import React, { useState } from "react";
import { mobileBreakpoint } from "../../Services/config";
import { useViewport } from "../ViewportProvider";
import styles from "./styles.module.scss";
import Toggle from "../Toggle";
import EventLogNew from "../EventLogNew";
import NoDataAvailable from "../NoDataAvailable";
import NoDataBox from "../NoDataBox";
import Insight from "../Insight";

const SUDayView = ({
  currentTab,
  isBathroom,
  noSplitting,
  events,
  noData,
  insights,
  insightsContext,
  children,
  isNewVariant,
  isOverview,
  isNighttime,
}) => {
  // Detect when we're on mobile
  const { width } = useViewport();
  const onMobileView = width <= mobileBreakpoint;

  // Local state
  const [showingEventLog, setShowEventLog] = useState(true);

  const noEvents = events?.length === 0;

  const noDataText =
    "If this user has recently installed the Lilli hub it may take up to 48 hours to start seeing data. If install was more than 48 hours ago please contact your Lilli Administrator. If you think there should be data and there is not, please contact us on +44 (0)758 800 8527 or email us at: support@intelligentlilli.com";

  return (
    <div className={styles.container}>
      {onMobileView && (
        <div className={styles.toggle}>
          <Toggle
            clickRight={() => {
              setShowEventLog(false);
            }}
            clickLeft={() => {
              setShowEventLog(true);
            }}
            leftSelected={showingEventLog}
            leftText="Event log"
            rightText="Insights"
          />
        </div>
      )}
      {(!onMobileView || showingEventLog) && (
        <div className={styles.eventlogContainer}>
          <EventLogNew
            currentTab={currentTab}
            events={events}
            noSplitting={noSplitting}
            noData={noEvents}
            isBathroom={isBathroom}
            isNewVariant={isNewVariant}
            isOverview={isOverview}
            isNighttime={isNighttime}
          />
        </div>
      )}
      {(!onMobileView || !showingEventLog) && (
        <div className={styles.no_data_insights}>
          {onMobileView && noData && (
            <>
              <h2>Insights</h2>
              <NoDataAvailable />
            </>
          )}
          {!onMobileView && (
            <>
              <h2>Insights</h2>
              {!noData && (
                <p className={styles.insights_context}>{insightsContext}</p>
              )}
              {noData && (
                <NoDataBox title={"No data available"} text={noDataText} />
              )}
            </>
          )}
          {!noData && (
            <div className={styles.insights_grid}>
              {insights?.map((insight, index) => (
                <Insight
                  insight={insight}
                  key={`${insight?.title}-${index}-dayview`}
                />
              ))}
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default SUDayView;
