import { createSlice } from "@reduxjs/toolkit";
import { ReportsV2Type } from "@intelligentlilli/api-layer";

// reportsv1 and reportsv2 have different schemas
// useReportsHook.js uses setReports for both v1 and v2 reports responses but just converts the v1 response into v2 structure

const initialState: ReportsV2Type[] = [];

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports(_, action) {
      return action.payload;
    },
  },
});

export const { setReports } = reportsSlice.actions;

export default reportsSlice.reducer;
