import React, { useEffect, useState } from "react";
import { getServerVersion } from "@intelligentlilli/api-layer";
import { useNavigate } from "react-router-dom";
// Components
import Page from "../../Components/Page";
import GuestPage from "../../Components/GuestPage";
import { NavigateBeforeIcon } from "../../Styles/Icons";
import SecondaryButton from "../../Components/SecondaryButton";
// state
import { useAppSelector } from "State/hooks";
import { ServerVersionResponseType } from "@intelligentlilli/api-layer";

interface AboutContentProps {
  clientVersion: string;
  server: string;
  serverVersion: ServerVersion;
  date: Date;
}

type ServerVersion = ServerVersionResponseType["body"];

const AboutContent = ({
  clientVersion,
  server,
  serverVersion,
  date,
}: AboutContentProps) => {
  return (
    <>
      <h2>About Lilli</h2>
      <p>
        All software has been designed, prototyped, developed and built in
        London, Milton Keynes and Woking.
      </p>
      <p>
        If you have any questions or feedback about our service please{" "}
        <a href="http://www.intelligentlilli.com/contact">get in touch</a>
      </p>
      <dl>
        <dt>App version</dt>
        <dd>{clientVersion}</dd>
        <dt>API URL</dt>
        <dd>{server}</dd>
        <dt>API Version</dt>
        <dd>{serverVersion ? serverVersion.version : "Unknown"}</dd>
      </dl>
      <p>
        We make use of, and are grateful to, many Open Source projects,
        including{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://reactjs.org/"
        >
          React
        </a>
        ,&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://clojure.org/"
        >
          Clojure
        </a>
        ,&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.h2database.com/html/main.html"
        >
          H2
        </a>
        &nbsp; and{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.material-ui.com/#/"
        >
          Material UI
        </a>
        .
      </p>
      <h2>
        &copy; {date.getFullYear()}{" "}
        <a style={{ color: "inherit" }} href="http://www.intelligentlilli.com/">
          Lilli
        </a>{" "}
        Limited.
      </h2>
    </>
  );
};

function About() {
  const navigate = useNavigate();

  // State
  const server = useAppSelector((state) => state.session.server);
  const clientVersion = useAppSelector((state) => state.session.clientVersion);
  const user = useAppSelector((state) => state.user);
  const [serverVersion, setServerVersion] = useState<ServerVersion | undefined>(
    undefined
  );

  useEffect(() => {
    getServerVersion(server, "web")
      .then((res) => {
        if (res.body) {
          setServerVersion(res.body);
        }
      })
      .catch((err) => console.log(err));
  }, [server]);

  const date = new Date();

  if (user) {
    return (
      <Page>
        <AboutContent
          clientVersion={clientVersion}
          server={server}
          serverVersion={serverVersion}
          date={date}
        />
      </Page>
    );
  } else {
    return (
      <GuestPage
        title={"About Lilli"}
        subtitle={
          <SecondaryButton
            startIcon={<NavigateBeforeIcon />}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </SecondaryButton>
        }
      >
        <AboutContent
          clientVersion={clientVersion}
          server={server}
          serverVersion={serverVersion}
          date={date}
        />
      </GuestPage>
    );
  }
}

export default About;
