import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// Components
import TimeSelector from "../TimeSelector";
import ToggleOnOff from "../ToggleOnOff";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton";
// API
import {
  putUserDischargeAlertSettings,
  putUserNightTimeSettings,
  getUserNightTimeSettings,
  getUserDischargeAlertSettings,
} from "@intelligentlilli/api-layer";
// State
import { useSelector } from "react-redux";
// Utils
import { isValidStartAndEndTime } from "../../Services/Utils";

const ServiceUserNotificationSettings = () => {
  const { id } = useParams(); // Get the service user id from the url parameter
  const server = useSelector((state) => state.session.server);
  const navigate = useNavigate();

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [notificationSettingsError, setNotificationSettingsError] =
    useState(null); //string | null
  const [organisationAlertsError, setOrganisationAlertsError] = useState(null);
  const [
    updateNotificationSuccessMessage,
    setUpdateNotificationSuccessMessage,
  ] = useState(null); // string | null

  const [initialTimes, setInitialTimes] = useState({
    startTime: "",
    endTime: "",
  }); // Store initial values

  // When first arrival onto the page, get the current nighttime notification settings
  useEffect(() => {
    // this gets the times
    getUserNightTimeSettings({ server, hubId: id, origin: "web" })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          setNotificationSettingsError(
            "Could not load settings. Refresh the screen to try again."
          );
          return;
        }
        if (res?.body) {
          setStartTime(res.body.startTime);
          setEndTime(res.body.endTime);
          setInitialTimes({
            startTime: res.body.startTime,
            endTime: res.body.endTime,
          });
        }
      })
      .catch((err) => {
        console.log("getNighttimeAlertSettings error:", err);
        setNotificationSettingsError(
          "Could not load settings. Refresh the screen to try again."
        );
      });

    getUserDischargeAlertSettings({ server, hubId: id, origin: "web" })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          setNotificationSettingsError(
            "Could not load settings. Refresh the screen to try again."
          );
          return;
        }
        if (res?.body) {
          setIsEnabled(res.body.enabled);
        }
      })
      .catch((err) => {
        console.log("getNighttimeAlertSettings error:", err);
        setNotificationSettingsError(
          "Could not load settings. Refresh the screen to try again."
        );
      });
  }, [server, id, navigate]);

  const haveTimesChanged = () => {
    return (
      startTime !== initialTimes.startTime || endTime !== initialTimes.endTime
    );
  };

  const updateNighttimeAlertTimes = (timeStart, timeEnd) => {
    if (!isValidStartAndEndTime(timeStart, timeEnd)) {
      return setNotificationSettingsError("Please select a valid time");
    }

    if (timeStart === timeEnd) {
      return setNotificationSettingsError(
        "Start and end times must be different"
      );
    }
    const errorMessage = "Could not save changes. Please try again.";
    putUserNightTimeSettings({
      server,
      hubId: id,
      startTime: timeStart,
      endTime: timeEnd,
      origin: "web",
    })
      .then((res) => {
        if (res.ok) {
          setNotificationSettingsError(""); // reset the error message
          setUpdateNotificationSuccessMessage("Settings saved successfully!"); // Show success message
          setTimeout(() => setUpdateNotificationSuccessMessage(null), 3000); // Clear message after 3 seconds
        } else {
          console.log(
            "updateNighttimeAlertTimes response is not OK:",
            res.body
          );
          setNotificationSettingsError(errorMessage);
        }
      })
      .catch((err) => {
        console.log("updateNighttimeAlertTimes error:", err);
        setNotificationSettingsError(errorMessage);
      });
  };

  const toggleOn = () => {
    setIsEnabled(true);
    putUserDischargeAlertSettings({
      server,
      hubId: id,
      isEnabled: true,
      origin: "web",
    })
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOn response is not OK:", res.body);
          setIsEnabled(false);
          setOrganisationAlertsError(
            "Could not enable notifications. Please try again."
          );
        }
        setOrganisationAlertsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("postEnableNighttimeAlerts error:", err);
        setIsEnabled(false);
        setOrganisationAlertsError(
          "Could not enable notifications. Please try again."
        );
      });
  };

  const toggleOff = () => {
    setIsEnabled(false);
    putUserDischargeAlertSettings({
      server,
      hubId: id,
      isEnabled: false,
      origin: "web",
    })
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOff response is not OK:", res.body);
          setIsEnabled(true);
          setOrganisationAlertsError(
            "Could not disable notifications. Please try again."
          );
        }
        setOrganisationAlertsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("postDisableNighttimeAlerts error:", err);
        setIsEnabled(true);
        setOrganisationAlertsError(
          "Could not disable notifications. Please try again."
        );
      });
  };

  return (
    <div className={styles.notifications}>
      <h1>Notification Preferences</h1>
      <p>Changes here will effect this user only.</p>
      <div className={styles.nightHours}>
        <h2>Night-time notification hours</h2>
        <p>
          Choose the time period for night-time notifications for this user.
          Changes here will affect all recipients.
        </p>
        <p className={styles.night_time_subtitle}>Night-time hours</p>
        <div className={styles.time_selector}>
          <div className={styles.time_selector_group}>
            <div className={styles.time}>
              <TimeSelector time={startTime} setTime={setStartTime} />
            </div>
            <div className={styles.time}> {"->"} </div>
            <div>
              <TimeSelector time={endTime} setTime={setEndTime} />
            </div>
          </div>
          {haveTimesChanged() ? (
            <LilliButton
              onClick={() => updateNighttimeAlertTimes(startTime, endTime)}
            >
              Save Changes
            </LilliButton>
          ) : null}
        </div>
        {updateNotificationSuccessMessage ? (
          <div className={styles.notifications_success_message}>
            {updateNotificationSuccessMessage}
          </div>
        ) : null}
        {notificationSettingsError ? (
          <p className={styles.notifications_error_message}>
            {notificationSettingsError}
          </p>
        ) : null}
      </div>
      <>
        <h2>Organisation alerts:</h2>
        <div className={styles.orgAlerts}>
          <div className={styles.orgAlerts_text}>
            <h2>Front door openings at night and high activity at night</h2>
            <p>
              These alerts are sent to your organisation's on-call service
              within 15 minutes of the detected event.
            </p>
          </div>
          <div className={styles.toggle}>
            <label>Email</label>
            <ToggleOnOff
              isEnabled={isEnabled}
              onToggleOn={() => toggleOn()}
              onToggleOff={() => toggleOff()}
              backgroundColor={theme.primary3}
            />
          </div>
        </div>
        {organisationAlertsError ? (
          <p className={styles.notifications_error_message}>
            {organisationAlertsError}
          </p>
        ) : null}
      </>
    </div>
  );
};

export default ServiceUserNotificationSettings;
