import React from "react";
import theme from "../../theme.scss";

export const InfoIcon = ({
  width,
  colour,
}: {
  width?: number;
  colour?: string;
}) => {
  return (
    <svg width={width || 20} viewBox="0 0 20 20" id="InfoIcon">
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
        fill={colour || theme.neutral4}
      />
      <path
        d="M10.0005 8.33337H9.16713C8.94612 8.33337 8.73416 8.42117 8.57788 8.57745C8.4216 8.73373 8.3338 8.94569 8.3338 9.16671C8.3338 9.38772 8.4216 9.59968 8.57788 9.75596C8.73416 9.91224 8.94612 10 9.16713 10H10.0005V15C10.0005 15.2211 10.0883 15.433 10.2445 15.5893C10.4008 15.7456 10.6128 15.8334 10.8338 15.8334C11.0548 15.8334 11.2668 15.7456 11.4231 15.5893C11.5793 15.433 11.6671 15.2211 11.6671 15V10C11.6671 9.55801 11.4915 9.13409 11.179 8.82153C10.8664 8.50897 10.4425 8.33337 10.0005 8.33337Z"
        fill={colour || theme.neutral4}
      />
      <path
        d="M10 6.66663C10.6904 6.66663 11.25 6.10698 11.25 5.41663C11.25 4.72627 10.6904 4.16663 10 4.16663C9.30964 4.16663 8.75 4.72627 8.75 5.41663C8.75 6.10698 9.30964 6.66663 10 6.66663Z"
        fill={colour || theme.neutral4}
      />
    </svg>
  );
};
