import React from "react";
import styles from "./styles.module.scss";
import Report from "../Report";

function ReportsTable({
  reportsToDisplay,
  setReportToDelete,
  setCloseConfirmModal,
  isDashboard,
}) {
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.table_header}>
            <th>Lilli user</th>
            <th>Report type</th>
            <th>Created</th>
            <th>Start date</th>
            <th>End date</th>
            <th />
          </tr>
        </thead>

        {/* table body */}
        <tbody>
          {reportsToDisplay.map((report, index) => (
            <Report
              isDashboard={isDashboard}
              key={index}
              report={report}
              setReportToDelete={setReportToDelete}
              setCloseConfirmModal={setCloseConfirmModal}
              moveModalUp={index > 5 && index === reportsToDisplay.length - 1}
            />
          ))}
        </tbody>
      </table>
      {reportsToDisplay?.length === 0 && (
        <div className={styles.no_reports}>
          <h2>No results found</h2>
          <p>
            Try adjusting your search to find the report you're looking for.
          </p>
        </div>
      )}
    </>
  );
}

export default ReportsTable;
