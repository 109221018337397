import React from "react";
import theme from "../../theme.scss";
import { IconProps } from "./types";

export const UnexpectedIcon = ({ width, colour }: IconProps) => {
  return (
    <svg width={width || 50} viewBox="0 0 50 50" id="UnexpectedIcon">
      <path
        d="M27.5005 0.226627C25.4832 -0.140918 23.4098 -0.0606192 21.4271 0.461841C19.4443 0.984301 17.6006 1.93615 16.0264 3.25004C14.4522 4.56392 13.186 6.20773 12.3174 8.06513C11.4488 9.92254 10.9991 11.9481 11 13.9986C11 14.9263 11.3685 15.8161 12.0246 16.4721C12.6806 17.1281 13.5703 17.4967 14.4981 17.4967C15.4258 17.4967 16.3156 17.1281 16.9716 16.4721C17.6276 15.8161 17.9962 14.9263 17.9962 13.9986C17.9953 12.9694 18.2215 11.9526 18.6587 11.0209C19.0959 10.0891 19.7332 9.26529 20.5253 8.60814C21.3175 7.951 22.2448 7.47671 23.2413 7.21911C24.2377 6.96151 25.2788 6.92694 26.2901 7.11786C27.672 7.38611 28.9426 8.06019 29.9395 9.05401C30.9364 10.0478 31.6145 11.3163 31.8871 12.6973C32.1624 14.1469 31.9723 15.6465 31.344 16.9815C30.7157 18.3166 29.6814 19.4189 28.389 20.1307C26.2487 21.3707 24.4802 23.1619 23.2675 25.3177C22.0549 27.4735 21.4425 29.9151 21.4943 32.388V34.9871C21.4943 35.9149 21.8628 36.8046 22.5188 37.4606C23.1748 38.1167 24.0646 38.4852 24.9923 38.4852C25.9201 38.4852 26.8098 38.1167 27.4659 37.4606C28.1219 36.8046 28.4904 35.9149 28.4904 34.9871V32.388C28.4465 31.1707 28.7241 29.9634 29.2952 28.8875C29.8663 27.8116 30.7108 26.9051 31.7436 26.2594C34.278 24.8674 36.319 22.7244 37.5857 20.1252C38.8525 17.5259 39.2829 14.598 38.8177 11.7442C38.3526 8.89031 37.0147 6.25065 34.9881 4.18819C32.9615 2.12573 30.3457 0.741768 27.5005 0.226627Z"
        fill={colour || theme.white}
      />
      <path
        d="M28.9962 45.4815C28.9962 43.5495 27.43 41.9834 25.4981 41.9834C23.5662 41.9834 22 43.5495 22 45.4815C22 47.4134 23.5662 48.9796 25.4981 48.9796C27.43 48.9796 28.9962 47.4134 28.9962 45.4815Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
