import styles from "./styles.module.scss";

const SensorsHeader = ({
  icon,
  isHub,
  loading,
  allSensorsOnline,
  missingSensorLocations,
  hubDidNotConnect,
}) => {
  const hubTitle = hubDidNotConnect
    ? "Hub did not connect"
    : loading
      ? "Connecting..."
      : "Connect the Lilli hub";
  const sensorsTitle = !allSensorsOnline
    ? "Connect the sensors"
    : missingSensorLocations
      ? "Provide sensor locations"
      : "Sensors online!";

  const title = isHub ? hubTitle : sensorsTitle;

  const hubStart = [
    {
      text: "1. To begin, take out the largest device in the box. This is the Lilli Hub.",
    },
    {
      text: "2. Plug the hub into a power outlet.",
    },
    { text: "3. Click 'Connect me'" },
  ];

  const hubIsConnecting = [
    {
      text: "Please wait until all three green lights are on. This can take up to 5 minutes.",
    },
    { text: "While you wait, put the batteries into the sensors." },
    {
      text: "Each sensor has a unique label (e.g., M1, M2, M3) indicating where in the home it should be placed.",
    },
  ];

  const hubDidNotConnectInstructions = [
    {
      text: "1. Click the 'Need Help?' button to troubleshoot.",
    },
    {
      text: "2. Test the connection again.",
    },
  ];

  const hubInstructions = hubDidNotConnect
    ? hubDidNotConnectInstructions
    : loading
      ? hubIsConnecting
      : hubStart;

  return (
    <div>
      <div className={styles.title}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {title}
      </div>
      {isHub &&
        hubInstructions?.map((instruction, index) => {
          if (instruction?.text) {
            return (
              <div key={index} className={styles.sensor_text}>
                {instruction.text}
              </div>
            );
          }
          return null;
        })}
      {isHub && (
        <div className={styles.sensor_text}>
          Do not close the app while you complete this step.
        </div>
      )}
    </div>
  );
};

export default SensorsHeader;
