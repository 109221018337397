import React from "react";
import styles from "./../../styles.module.scss";
import PrimaryButton from "Components/PrimaryButton";
import SecondaryButton from "Components/SecondaryButton";
import theme from "../../../../Styles/theme.scss";

interface InstallationNotesProps {
  showErrorMessage: boolean;
  editingNotes: boolean;
  setEditingNotes: React.Dispatch<React.SetStateAction<boolean>>;
  serviceUser: any;
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setShowErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
  updateNotes: (note: string) => void;
}

const InstallationNotes = ({
  showErrorMessage,
  editingNotes,
  setEditingNotes,
  serviceUser,
  notes,
  setNotes,
  setShowErrorMessage,
  updateNotes,
}: InstallationNotesProps) => {
  return (
    <>
      {" "}
      <h2 className={styles.subtitle}>Installation Notes:</h2>
      {showErrorMessage && (
        <div className={styles.error}>
          Error installation notes failed to save
        </div>
      )}
      {!editingNotes && (
        <p data-private className={styles.notes}>
          {serviceUser?.serviceUserProperties?.installationNotes ||
            "No notes added yet"}
        </p>
      )}
      {editingNotes && (
        <div>
          <textarea
            data-private
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add installation notes"
            maxLength={600}
            style={{
              height: "180px",
              width: "100%",
              resize: "none",
              borderRadius: 5,
              padding: 12,
              boxSizing: "border-box",
              fontFamily: "Inter",
              border: "0.72px solid #B8B8B8",
              fontSize: theme.baseFontSize,
            }}
          />
          <div className={styles.installationNotes_buttons}>
            <SecondaryButton
              style={{ color: theme.risk3 }}
              onClick={() => {
                setEditingNotes(false);
                setShowErrorMessage(false);
              }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => updateNotes(notes)}
              style={{ marginLeft: 32, padding: 0 }}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallationNotes;
