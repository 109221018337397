import React from "react";
import theme from "../../../Styles/theme.scss";

interface SuccessTickIconProps {
  colour?: string;
  width?: string | number;
}

export const SuccessTickIcon = ({ colour, width }: SuccessTickIconProps) => {
  return (
    <svg
      width={width || "20"}
      height={Number(width) * 0.75 || "15"}
      viewBox="0 0 14 15"
      fill="none"
    >
      <g id="SuccessTickIcon_Outer">
        <g id="SuccessTickIcon_Inner">
          <path
            id="Vector"
            d="M6.99967 0.833313C3.3179 0.833313 0.333008 3.81821 0.333008 7.49998C0.333008 11.1818 3.3179 14.1666 6.99967 14.1666C10.6814 14.1666 13.6663 11.1818 13.6663 7.49998C13.6624 3.81977 10.6799 0.837219 6.99967 0.833313ZM6.99967 13.0555C3.93145 13.0555 1.4442 10.5682 1.4442 7.49998C1.4442 4.43175 3.93145 1.94451 6.99967 1.94451C10.0679 1.94451 12.5551 4.43175 12.5551 7.49998C12.5517 10.5669 10.0663 13.0521 6.99967 13.0555Z"
            fill={colour || theme.expected2}
          />
          <path
            id="Vector_2"
            d="M9.9854 5.38566C9.76847 5.16873 9.41689 5.16873 9.1997 5.38566L6.07368 8.51221L4.58043 7.03826C4.36558 6.81821 4.013 6.81378 3.79295 7.02862C3.5729 7.24347 3.56847 7.59608 3.78331 7.81613L5.30779 9.31612C5.50102 9.52445 5.7708 9.64476 6.05491 9.64945H6.07315C6.35153 9.65049 6.61873 9.53982 6.81482 9.34216L9.9854 6.17108C10.2023 5.95415 10.2023 5.60259 9.9854 5.3854V5.38566Z"
            fill={colour || theme.expected2}
          />
        </g>
      </g>
    </svg>
  );
};
