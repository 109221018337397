import { getDecommissionReasons } from "@intelligentlilli/api-layer";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useGetOffboardingReasons = ({ serviceUserId }) => {
  // redux state
  const server = useSelector((state) => state.session.server);

  // local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getReasons = useCallback(() => {
    setLoading(true);
    setError(null);
    getDecommissionReasons({ server, hubId: serviceUserId, origin: "web" })
      .then((res) => {
        if (res.ok && res.body) {
          setLoading(false);
          setData(res.body);
        } else {
          setLoading(false);
          setError(true);
          setData(null);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setData(null);
      });
  }, [server, serviceUserId]);

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  return {
    loading,
    error,
    data,
  };
};
