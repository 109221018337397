import { createSlice } from "@reduxjs/toolkit";
import { ReportTypes } from "@intelligentlilli/api-layer";

const initialState: ReportTypes = [];

const reportTypesSlice = createSlice({
  name: "reportTypes",
  initialState,
  reducers: {
    setReportTypes(_, action) {
      return action.payload;
    },
  },
});

export const { setReportTypes } = reportTypesSlice.actions;

export default reportTypesSlice.reducer;
