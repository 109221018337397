import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ReportsTable from "../ReportsTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Hooks
import { useReportsHook } from "../../Services/Hooks";
// Components
import ConfirmDeleteReportModal from "../ConfirmDeleteReportModal";

const DashboardReports = ({ searchValue }) => {
  const navigate = useNavigate();

  // redux state
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const user = useSelector((state) => state.user);
  // Local state
  const [reportsToDisplay, setReportsToDisplay] = useState([]);
  const [reportToDelete, setReportToDelete] = useState(null);

  // custom hooks
  const {
    reports,
    reportTypes,
    deleteSelectedReport,
    closeConfirmModal,
    setCloseConfirmModal,
  } = useReportsHook(server, navigate);

  // When the user searches or sorts update the reports to display
  useEffect(() => {
    let filteredReports = [...reports];
    // First extract the userstring and convert all report names to lower case as the sorting functions cannot handle a case mix
    filteredReports = filteredReports.map((report) => {
      const userstring = serviceUsers?.find(
        (hub) => hub.id === parseInt(report.entity_id)
      )?.userstring;
      return {
        ...report,
        userstring: userstring,
        userstringLower: userstring?.toLowerCase(),
        userstringArray: userstring?.toLowerCase().split(" "),
      };
    });

    // Remove any reports without a userstring
    // this means that any reports generated in a different organisation will not show
    filteredReports = filteredReports.filter((report) => report.userstring);

    // This function searches an array of strings and determines whether any of them match the search value
    const searchArray = (array, inputValue, inputLength) => {
      let matched = false;
      array?.forEach((string) => {
        if (string.slice(0, inputLength) === inputValue) {
          matched = true;
        }
      });
      return matched;
    };

    // Apply the search
    if (searchValue.length > 0) {
      const inputValue = searchValue.trim().toLowerCase();
      const inputLength = inputValue.length;
      filteredReports = filteredReports.filter(
        (report) =>
          report.userstringLower?.slice(0, inputLength) === inputValue ||
          searchArray(report.userstringArray, inputValue, inputLength)
      );
    }

    const reportsWithTitles = filteredReports.map((report) => {
      const title =
        reportTypes.find((type) => type.id === report.report_type)?.title ||
        null;
      return { ...report, title: title };
    });
    // filter out any without titles
    setReportsToDisplay(reportsWithTitles.filter((report) => report.title));
  }, [
    user.key,
    user.organisation,
    server,
    reports,
    searchValue,
    serviceUsers,
    reportTypes,
  ]);

  return (
    <div className={styles.dashboard_reports_container}>
      <div>
        <ReportsTable
          reportsToDisplay={reportsToDisplay}
          setReportToDelete={setReportToDelete}
          setCloseConfirmModal={setCloseConfirmModal}
          isDashboard={true}
        />
      </div>
      {!closeConfirmModal && (
        <ConfirmDeleteReportModal
          setCloseConfirmModal={setCloseConfirmModal}
          reportToDelete={reportToDelete}
          deleteSelectedReport={deleteSelectedReport}
        />
      )}
    </div>
  );
};

export default DashboardReports;
