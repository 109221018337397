import React, { useState } from "react";
import styles from "./styles.module.scss"; // Unified styles import
import theme from "../../../Styles/theme.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { mobileBreakpoint } from "../../../Services/config";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialSensors,
  setSensors,
} from "../../../State/slices/sensors-slice";
import { setSensorLocations } from "../../../State/slices/sensorLocations-slice";
// Components
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import { useViewport } from "../../../Components/ViewportProvider";
// Constants
import { INITAL_SENSORS } from "../../../Services/Constants";
// Hooks
import { useNativeInstallAuth } from "../../../Services/Hooks";

const Install = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  // Set cookie headeres for native install
  const { isNative } = useNativeInstallAuth({
    path: "install/native/start",
  });

  const serviceUsers = useSelector((state) => state.serviceUsers);

  // Local state for service user selection
  const [serviceUserSelected, setServiceUserSelected] = useState(
    location.serviceUser
  );
  const startedInstallation =
    serviceUserSelected?.installationStatus === "started";

  const modifiedSUs = serviceUsers
    .filter(
      (serviceUser) =>
        serviceUser.serviceUserProperties?.installationStatus !== "complete"
    )
    .map((serviceUser) => ({
      value: serviceUser.id,
      label: `${serviceUser.userstring} ${
        serviceUser.serviceUserProperties?.installationStatus === "started"
          ? "(started)"
          : ""
      }`,
      postcode:
        serviceUser.postcode || serviceUser.serviceUserProperties?.postcode,
      houseNumber:
        serviceUser.houseNumberOrName ||
        serviceUser.serviceUserProperties?.houseNumberOrName,
      devicestring: serviceUser.devicestring,
      installationStatus: serviceUser.serviceUserProperties?.installationStatus,
    }));

  const onSelectNotStartedSU = () => {
    dispatch(setInitialSensors(INITAL_SENSORS));
    dispatch(setSensors(null));
    dispatch(setSensorLocations(null));
  };

  // Correctly handle native and web navigation routes
  const baseRoute = isNative ? "/install/native" : "/install";
  const onSelectInProgressSU = () => {
    dispatch(setInitialSensors(INITAL_SENSORS));
    navigate(
      `${baseRoute}/hub/${serviceUserSelected.value}/${serviceUserSelected.devicestring}`
    );
  };

  const onConfirmSUDetails = () => {
    navigate(`${baseRoute}/link/${serviceUserSelected.value}`);
  };

  const suAddress =
    serviceUserSelected &&
    serviceUserSelected?.houseNumber &&
    serviceUserSelected?.postcode
      ? [serviceUserSelected?.houseNumber, serviceUserSelected?.postcode]
      : ["No address found"];

  return (
    <InstallationCard>
      <div className={styles.card_content}>
        <img
          src="/images/installation/lilli-installation-graphic.png"
          alt="Lilli"
          className={styles.logo}
        />

        <h2 className={styles.select_SU_header}>Welcome!</h2>
        <p>Select which Lilli user you are installing for:</p>
        <label data-private>
          <p className={styles.selectLabel}>Select Lilli user</p>
          <Select
            data-private
            placeholder="Select"
            options={modifiedSUs}
            name="serviceUsers"
            value={serviceUserSelected}
            onChange={setServiceUserSelected}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (baseStyles, { isFocused }) => ({
                ...baseStyles,
                borderColor: isFocused ? theme.primary3 : theme.neutral3,
                marginTop: 4,
                fontSize: theme.bodyMedium,
                color: theme.neutral7,
                borderRadius: 8,
                ":hover": { borderColor: theme.primary3 },
                boxShadow: "none",
              }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "16px",
                backgroundColor: isFocused ? theme.neutral2 : null,
                color: theme.neutral7,
                ":active": { backgroundColor: theme.neutral3 },
              }),
            }}
          />
        </label>

        {serviceUserSelected && suAddress.length > 0 && (
          <div data-private className={styles.confirm_card}>
            {suAddress.map((line, index) => (
              <div key={index} className={styles.confirm_address}>
                {line}
              </div>
            ))}
          </div>
        )}

        {serviceUserSelected?.devicestring && startedInstallation && (
          <div className={styles.continueInstallation}>
            <p>
              A previous attempt was made to install a Lilli kit (serial number{" "}
              {serviceUserSelected.devicestring}).
            </p>
            <p>
              Would you like to continue the installation or start with a new
              kit?
            </p>
          </div>
        )}

        <div className={styles.buttons}>
          {startedInstallation && serviceUserSelected?.devicestring && (
            <InstallationGoBackButton
              isDesktop={startedInstallation && isDesktop}
              onClick={onSelectInProgressSU}
              label="Continue installation"
            />
          )}
          <InstallationNextButton
            isDesktop={startedInstallation && isDesktop}
            onClick={() => {
              onSelectNotStartedSU();
              onConfirmSUDetails();
            }}
            disabled={!serviceUserSelected}
            label={startedInstallation ? "Install new kit" : "Next"}
          />
        </div>
      </div>
    </InstallationCard>
  );
};

export default Install;
