import { createSlice } from "@reduxjs/toolkit";
import {
  HubRiskScoreV2BodyType,
  EventsType,
  IndependenceAnalysisType,
  HubMainDoorActivityType,
} from "@intelligentlilli/api-layer";
import { CombinedContinuousInsightType } from "@intelligentlilli/lilli-utils";
// todo: this is difficult/needs thought as setServiceUsersData/the data stored can be different depending on if you're a manager/admin or if you're staff

type RiskScores = HubRiskScoreV2BodyType["dayRatings"][0];

type ExtendedEventsLog = EventsType & {
  lastFetched: string;
  isComplete: boolean;
};

type EventsLog = Record<string, ExtendedEventsLog>;

interface ServiceUserRiskScores {
  riskScores: RiskScores[];
  weekRisks: {
    atRisk: number | null;
    unexpected: number | null;
    expected: number | null;
  };
  riskStatus: number | null;
  riskStatusSustenance: number | null;
  riskStatusMovement: number | null;
  riskStatusIndependence: number | null;
  riskStatusSleep: number | null;
  events?: EventsLog;
}

export type TimeOutsideV2ReduxType = {
  insights: CombinedContinuousInsightType[];
};

export type ProcessedMainDoorActivithyType = HubMainDoorActivityType & {
  date: string;
  isComplete: boolean;
};
export type MainDoorActivityReduxType = ProcessedMainDoorActivithyType[];
export interface ServiceUserDataState {
  [key: string]: {
    riskScores?: ServiceUserRiskScores;
    timeOutsideV2?: TimeOutsideV2ReduxType;
    independenceAnalysis?: IndependenceAnalysisType;
    mainDoorActivity?: MainDoorActivityReduxType;
  };
}

const initialState: ServiceUserDataState = {};

const serviceUsersDataSlice = createSlice({
  name: "serviceUsersData",
  initialState,
  reducers: {
    setServiceUsersData(_, action) {
      return action.payload;
    },
    updateServiceUserData(state, action) {
      state[action.payload.hubId] = {
        ...state[action.payload.hubId],
        ...action.payload.update,
      };
    },
    updateServiceUserEvents(state, action) {
      const { hubId, key, events } = action.payload;
      //@ts-expect-error
      state[hubId] = {
        ...state[hubId],
        events: {
          ...(state[hubId]?.events || {}),
          [key]: events,
        },
      };
    },
  },
});

export const {
  setServiceUsersData,
  updateServiceUserData,
  updateServiceUserEvents,
} = serviceUsersDataSlice.actions;
export default serviceUsersDataSlice.reducer;
