import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import theme from "Styles/theme.scss";
import { IconProps } from "./types";

const NewCircleLeftIcon = ({ width, colour }: IconProps) => {
  return (
    <FontAwesomeIcon
      icon={faCircleLeft}
      fontSize={width ?? "16px"}
      color={colour ?? theme.neutral7}
    />
  );
};

export default NewCircleLeftIcon;
