import { useEffect, useState } from "react";
// UTILS
import { endOfDay } from "date-fns";
import { TimeOutsideV2ReduxType } from "State/slices/serviceUsersData-slice";
import { CombinedContinuousInsightType } from "@intelligentlilli/lilli-utils";

interface IUsePrepareTimeOutsideData {
  urlStartDateString: string;
  urlEndDateString: string;
  urlDateType: string;
  prevEndDateString: string;
  prevStartDateString: string;
  reduxTimeOutsideData?: TimeOutsideV2ReduxType;
}

// This hook is used to generate data that are used in the Event Log
export const usePrepareTimeOutsideData = ({
  reduxTimeOutsideData,
  urlStartDateString,
  urlEndDateString,
  urlDateType,
  prevEndDateString,
  prevStartDateString,
}: IUsePrepareTimeOutsideData) => {
  const [timeOutsideData, setTimeOutsideData] = useState<
    CombinedContinuousInsightType[]
  >([]);
  const [timeOutsideDataPrevious, setTimeOutsideDataPrevious] = useState<
    CombinedContinuousInsightType[]
  >([]);

  useEffect(() => {
    const endDate = urlEndDateString
      ? endOfDay(new Date(urlEndDateString))
      : new Date();
    const startDate = urlStartDateString
      ? endOfDay(new Date(urlStartDateString))
      : new Date();

    const prevEndDate = prevEndDateString
      ? endOfDay(new Date(prevEndDateString))
      : new Date();
    const prevStartDate = prevStartDateString
      ? endOfDay(new Date(prevStartDateString))
      : new Date();

    setTimeOutsideData(
      getRelevantTimePeriod({
        array: reduxTimeOutsideData?.insights,
        startDate: startDate,
        endDate: endDate,
      })
    );
    setTimeOutsideDataPrevious(
      getRelevantTimePeriod({
        array: reduxTimeOutsideData?.insights,
        startDate: prevStartDate,
        endDate: prevEndDate,
      })
    );
  }, [
    reduxTimeOutsideData,
    urlStartDateString,
    urlEndDateString,
    urlDateType,
    prevEndDateString,
    prevStartDateString,
  ]);

  return {
    timeOutsideData,
    timeOutsideDataPrevious,
  };
};

// Functions that help with extracting and processing bathroom activity data
interface IGetRelevantTimePeriod {
  array?: CombinedContinuousInsightType[];
  startDate: Date;
  endDate: Date;
}
const getRelevantTimePeriod = ({
  array,
  startDate,
  endDate,
}: IGetRelevantTimePeriod) => {
  return (
    array?.filter((event) => {
      return (
        endOfDay(new Date(event?.date)) >= new Date(startDate) &&
        endOfDay(new Date(event?.date)) <= new Date(endDate)
      );
    }) || []
  );
};
