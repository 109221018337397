import React, { useEffect, useRef } from "react";
import { Link, NavigateFunction, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import { mobileBreakpoint, reportsBreakpoint } from "../../Services/config";
import { useViewport } from "../ViewportProvider";
// Components
import { CloseIcon } from "../../Styles/Icons/DesignSystem";
import { LilliLogoTealSvg } from "../../Styles/LilliLogoTealSvg";
import theme from "../../Styles/theme.scss";
import { setShowSideDrawer } from "../../State/slices/session-slice";
import { useAppDispatch, useAppSelector } from "State/hooks";
import {
  getNavigationItems,
  NavLinkType,
  NavTypeIcon,
  shouldHighlight,
} from "./utils";

interface SideDrawerProps {
  navigate: NavigateFunction;
}

const SideDrawer = ({ navigate }: SideDrawerProps) => {
  // Use react router hook to get the current url
  let location = useLocation();
  const currentRoute = location.pathname;

  // Redux state
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.session);
  const showSideDrawer = session?.showSideDrawer;
  // Tap into the previous state of show side drawer so that we know when to activate the link animation
  const prevShowSideDrawer = useRef<boolean | undefined>();
  // Update the previous state when state changes.
  useEffect(() => {
    prevShowSideDrawer.current = showSideDrawer;
  }, [showSideDrawer]);

  // We only want to animate the buttons where the user has just opened the side drawer and not when they are selecting links within the side drawer
  const shouldAnimate = () =>
    showSideDrawer && prevShowSideDrawer.current === false;

  // Get the width of the viewport for responsive design
  const { width } = useViewport();
  const desktopView = width > mobileBreakpoint;
  const showReports = width > reportsBreakpoint;

  // Get the user from redux state
  const user = useAppSelector((state) => state.user);

  const communityPageSessionId = useAppSelector(
    (state) => state.session.ziplineAuth
  );
  const communityPageURL = `https://community.intelligentlilli.com?action=lilli_auth&token=${communityPageSessionId}`;

  // Get the navigation items for the side drawer
  const navigationItems = getNavigationItems(user?.roles, showReports);

  // A navigation link component that determines what to render for each navigation item
  const NavLink = ({ item }: NavLinkType) => {
    const NavIcon = NavTypeIcon[item.type];

    if (!showSideDrawer) {
      return (
        <Link
          to={item.link}
          className={`${styles.drawerNav} ${
            shouldHighlight(item, currentRoute) ? styles.drawerNav_active : null
          }`}
          aria-label={item.ariaLabel}
        >
          <NavIcon
            width={20}
            colour={
              shouldHighlight(item, currentRoute) ? "#1E2524" : theme.neutral4
            }
          />
        </Link>
      );
    }
    // Otherwise return the link for the side drawer
    return (
      <Link
        to={item.link}
        className={`${styles.drawerNav} ${styles.drawerNav_open} ${
          shouldAnimate() ? styles.drawerNav_animate : null
        } ${
          shouldHighlight(item, currentRoute) ? styles.drawerNav_active : null
        }`}
        aria-label={item.ariaLabel}
      >
        <div className={styles.drawerNav_item}>
          <NavIcon
            width={20}
            colour={
              shouldHighlight(item, currentRoute) ? "#1E2524" : theme.neutral4
            }
          />
          {showSideDrawer && (
            <div
              className={`${styles.drawerNav_item_text} ${
                shouldHighlight(item, currentRoute)
                  ? styles.drawerNav_item_text_active
                  : null
              }`}
            >
              {item.name}
            </div>
          )}
        </div>
      </Link>
    );
  };

  // A closed side drawer. n.b. the css takes care of hiding this on mobile
  if (!showSideDrawer) {
    return (
      <div
        className={styles.hoverArea}
        onMouseOver={() => dispatch(setShowSideDrawer(true))}
      >
        <div className={`${styles.drawer} ${styles.drawer_closed}`}>
          <div className={styles.drawer_logo} onClick={() => navigate("/")}>
            {desktopView && <LilliLogoTealSvg />}
          </div>
          {navigationItems?.map((item, index) => (
            <NavLink key={index} item={item} />
          ))}
          <NavLink
            item={{
              link: communityPageURL,
              name: "Community",
              type: "Community",
              ariaLabel: "Community page",
            }}
          />
        </div>
      </div>
    );
  }
  // Otherwise we render the opened sidedrawer when 'show' is true (web and mobile)
  return (
    showSideDrawer && (
      <div
        className={styles.drawer}
        onMouseLeave={() => dispatch(setShowSideDrawer(false))}
      >
        {desktopView ? (
          <div className={styles.drawer_logo} onClick={() => navigate("/")}>
            <LilliLogoTealSvg />
          </div>
        ) : (
          <div
            className={styles.drawer_logo}
            onClick={() => dispatch(setShowSideDrawer(false))}
          >
            <div style={{ marginLeft: 7.5 }}>
              <CloseIcon width={16} color={theme.primary6} />
            </div>
          </div>
        )}
        {navigationItems?.map((item, index) => (
          <NavLink key={index} item={item} />
        ))}
        <NavLink
          item={{
            link: communityPageURL,
            name: "Community",
            type: "Community",
            ariaLabel: "Community page",
          }}
        />
      </div>
    )
  );
};

export default SideDrawer;
