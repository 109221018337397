import React from "react";
import theme from "../../theme.scss";

interface DeleteIconProps {
  width?: string | number;
  height?: string | number;
  colour?: string;
}

export const DeleteIcon = ({ width, height, colour }: DeleteIconProps) => {
  return (
    <svg
      width={width || 16}
      height={height || 17}
      viewBox="0 0 16 17"
      fill={colour || theme.white}
    >
      <path d="M14 3.16667H11.9333C11.7786 2.41428 11.3692 1.73823 10.7741 1.25248C10.1791 0.766727 9.43478 0.50097 8.66665 0.5L7.33331 0.5C6.56518 0.50097 5.82086 0.766727 5.22581 1.25248C4.63077 1.73823 4.22138 2.41428 4.06665 3.16667H1.99998C1.82317 3.16667 1.6536 3.2369 1.52858 3.36193C1.40355 3.48695 1.33331 3.65652 1.33331 3.83333C1.33331 4.01014 1.40355 4.17971 1.52858 4.30474C1.6536 4.42976 1.82317 4.5 1.99998 4.5H2.66665V13.1667C2.6677 14.0504 3.01923 14.8976 3.64413 15.5225C4.26902 16.1474 5.11625 16.4989 5.99998 16.5H9.99998C10.8837 16.4989 11.7309 16.1474 12.3558 15.5225C12.9807 14.8976 13.3323 14.0504 13.3333 13.1667V4.5H14C14.1768 4.5 14.3464 4.42976 14.4714 4.30474C14.5964 4.17971 14.6666 4.01014 14.6666 3.83333C14.6666 3.65652 14.5964 3.48695 14.4714 3.36193C14.3464 3.2369 14.1768 3.16667 14 3.16667ZM7.33331 1.83333H8.66665C9.08016 1.83384 9.4834 1.96225 9.82106 2.20096C10.1587 2.43967 10.4143 2.77699 10.5526 3.16667H5.44731C5.5857 2.77699 5.84125 2.43967 6.1789 2.20096C6.51656 1.96225 6.9198 1.83384 7.33331 1.83333ZM12 13.1667C12 13.6971 11.7893 14.2058 11.4142 14.5809C11.0391 14.956 10.5304 15.1667 9.99998 15.1667H5.99998C5.46955 15.1667 4.96084 14.956 4.58577 14.5809C4.21069 14.2058 3.99998 13.6971 3.99998 13.1667V4.5H12V13.1667Z" />
      <path d="M6.66667 12.5C6.84348 12.5 7.01305 12.4298 7.13807 12.3048C7.2631 12.1797 7.33333 12.0102 7.33333 11.8334V7.83335C7.33333 7.65654 7.2631 7.48697 7.13807 7.36195C7.01305 7.23693 6.84348 7.16669 6.66667 7.16669C6.48986 7.16669 6.32029 7.23693 6.19526 7.36195C6.07024 7.48697 6 7.65654 6 7.83335V11.8334C6 12.0102 6.07024 12.1797 6.19526 12.3048C6.32029 12.4298 6.48986 12.5 6.66667 12.5Z" />
      <path d="M9.33335 12.5C9.51016 12.5 9.67973 12.4298 9.80476 12.3048C9.92978 12.1797 10 12.0102 10 11.8334V7.83335C10 7.65654 9.92978 7.48697 9.80476 7.36195C9.67973 7.23693 9.51016 7.16669 9.33335 7.16669C9.15654 7.16669 8.98697 7.23693 8.86195 7.36195C8.73693 7.48697 8.66669 7.65654 8.66669 7.83335V11.8334C8.66669 12.0102 8.73693 12.1797 8.86195 12.3048C8.98697 12.4298 9.15654 12.5 9.33335 12.5Z" />
    </svg>
  );
};
