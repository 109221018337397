import React from "react";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { Field } from "react-final-form";
import LilliSelect from "../../../Components/LilliDesignSystem/LilliSelect";

const BehaviourSelection = () => {
  // value will be sent to POST /postHubProperties
  const behaviourValues = [
    {
      label: "Risk of leaving the property",
      value: "Risk of leaving the property",
    },
    { label: "General mobility indoors", value: "General mobility indoors" },
    {
      label: "Bathroom, washing & personal care",
      value: "Bathroom, washing & personal care",
    },
    { label: "Time spent outside", value: "Time spent outside" },
    {
      label: "Eating & drinking",
      value: "Eating & drinking",
    },
    {
      label: "Stair use",
      value: "Stair use",
    },
    {
      label: "ADLs (Activities of Daily Living)",
      value: "Activities of Daily Living",
    },

    { label: "Not sure", value: "Not sure" },
  ];

  return (
    <div className={styles.behaviour_container}>
      <div>
        <div className={styles.behaviour_dropdown_section}>
          <Field name="firstBehaviour">
            {({ input }) => {
              return (
                <div className={classNames(styles.field)}>
                  <div className={styles.label}>
                    What is the main behaviour that needs to be monitored
                  </div>
                  <LilliSelect
                    withinModal
                    placeholder="Select main behaviour"
                    options={behaviourValues}
                    {...input}
                  />
                </div>
              );
            }}
          </Field>
          <Field name="secondBehaviour">
            {({ input }) => {
              return (
                <div className={classNames(styles.field)}>
                  <div className={styles.label}>
                    Is there another behaviour that needs to be monitored
                  </div>
                  <LilliSelect
                    withinModal
                    placeholder="Select another behaviour"
                    options={behaviourValues}
                    {...input}
                  />
                </div>
              );
            }}
          </Field>
        </div>
      </div>
      <Field name="dayNightTimeBehaviour">
        {({ input }) => {
          return (
            <div
              className={classNames(
                styles.field,
                styles.behaviour_daynightime_section
              )}
            >
              <div className={styles.label}>
                Are you more interested in night time or day time activity
              </div>
              <LilliSelect
                withinModal
                options={[
                  { label: "Night time", value: "Night time" },
                  { label: "Day time", value: "Day time" },
                  { label: "Both", value: "Both" },
                ]}
                placeholder="Select activity type"
                {...input}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default BehaviourSelection;
