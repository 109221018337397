import React from "react";
import theme from "../../theme.scss";
import { IconProps } from "./types";

export const BatteryRiskIcon = ({ width, colour }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width ?? "24px"}
      viewBox="0 -960 960 960"
      width={width ?? "24px"}
      fill={colour ?? theme.neutral7}
    >
      <path d="M440-400h80v-240h-80v240Zm40 160q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240ZM320-80q-17 0-28.5-11.5T280-120v-640q0-17 11.5-28.5T320-800h80v-80h160v80h80q17 0 28.5 11.5T680-760v640q0 17-11.5 28.5T640-80H320Zm40-80h240v-560H360v560Zm0 0h240-240Z" />
    </svg>
  );
};
