import React, { useEffect, useState, useRef } from "react";

const VideoPlayer = ({
  src,
  shouldLoop,
  videoType,
  fallbackImage,
  imageAlt,
}) => {
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  return (
    <div>
      {!videoError ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <video
            height="100%"
            controls
            onError={() => setVideoError(true)}
            ref={videoRef}
            disablePictureInPicture
            loop={shouldLoop || false}
            muted
            autoPlay
            controlsList="nodownload nofullscreen noremoteplayback"
            playsInline
            type={videoType || "video/mp4"}
            style={{ borderRadius: "8px" }}
          >
            <source src={src} />
          </video>
        </div>
      ) : fallbackImage ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
          }}
        >
          {" "}
          <img
            src={fallbackImage}
            alt={imageAlt}
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />{" "}
        </div>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
