import React, { useState } from "react";
import styles from "./styles.module.scss";
import SensorSticker from "../SensorSticker";
import SensorStatusTag from "../SensorStatusTag";

import classNames from "classnames";
import { getLastEventStatus } from "Services/Utils/newDeviceMonitoring/getLastEvent";
import {
  AugmentDeviceData,
  SensorStatus,
} from "Services/Utils/newDeviceMonitoring/types";

interface SensorProps {
  device: AugmentDeviceData;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sensorStatus: SensorStatus;
  isOffline: boolean;
}

const Sensor = ({ device, onClick, sensorStatus, isOffline }: SensorProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { lastEvent } = getLastEventStatus(device);

  return (
    <div
      className={classNames(styles.sensor, {
        [styles.hover]: sensorStatus.variant === "offline",
      })}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.sensor_left}>
        <div className={styles.sensor_name_container}>
          <p className={styles.sensor_name}>
            {device?.displayName || device?.defaultName}
          </p>
          {device.sensorLocation && device.sensorLocation !== "Other" ? (
            <p className={styles.location}>{device.sensorLocation}</p>
          ) : null}
        </div>
        <div className={styles.sensor_signal}>
          <SensorStatusTag sensorStatus={sensorStatus} isHovered={isHovered} />
          {isOffline && lastEvent ? (
            <p
              className={classNames(
                styles.last_online,
                styles.mobile_last_online
              )}
            >
              Last online: {lastEvent}
            </p>
          ) : null}
        </div>
      </div>
      <div className={styles.sensor_right}>
        <div className={styles.device_group}>
          <img src={device?.img} className={styles.image} alt="sensor" />
          <SensorSticker label={device?.label} />
        </div>
        {isOffline && lastEvent ? (
          <div className={styles.desktop_last_online}>
            <p className={styles.last_online}>Last online: {lastEvent}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Sensor;
