import {
  HUB_TROUBLESHOOTING_GENERIC_HEADER,
  HUB_TROUBLESHOOTING_HEADERS,
  HUB_TROUBLESHOOTING_INSTRUCTIONS,
  HUB_TROUBLESHOOTING_OPTIONS,
} from "Services/Constants/Installation/hubTroubleshooting";
import {
  TROUBLESHOOTING_GENERIC_HEADER,
  TROUBLESHOOTING_HEADERS,
  TROUBLESHOOTING_INSTRUCTIONS,
  TROUBLESHOOTING_OPTIONS,
} from "Services/Constants/Installation/troubleshooting";

export const getTroubleshootingProps = (
  deviceType: "Hub" | "Motion" | "Power" | "Door"
) => {
  const isHub = deviceType === "Hub";

  return {
    sensorName: deviceType,
    troubleshootingOptions: isHub
      ? HUB_TROUBLESHOOTING_OPTIONS
      : TROUBLESHOOTING_OPTIONS,
    troubleshootingHeaders: isHub
      ? HUB_TROUBLESHOOTING_HEADERS
      : TROUBLESHOOTING_HEADERS,
    troubleshootingInstructions: isHub
      ? HUB_TROUBLESHOOTING_INSTRUCTIONS
      : TROUBLESHOOTING_INSTRUCTIONS,
    troubleshootingGenericHeader: isHub
      ? HUB_TROUBLESHOOTING_GENERIC_HEADER
      : TROUBLESHOOTING_GENERIC_HEADER,
  };
};
