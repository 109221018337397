import { IndependenceHourlyDataType } from "@intelligentlilli/api-layer";
import {
  CombinedContinuousInsightType,
  ProcessedDailyIndependenceDataType,
} from "@intelligentlilli/lilli-utils";
import { startOfDay, endOfDay } from "date-fns";
import { useEffect, useState } from "react";

// We're switching over to the new time outside model and API schema.
// This hook will convert the old independence payload into the new format.
// It will also combine the two responses where the requested data crosses over the change over date.

const convertHourlyData = (hourlyData?: IndependenceHourlyDataType) => {
  // Step 1: Extract the periods outside from each hour and remove any that don't have a cameBackAt or wentOutAt
  const step1 = hourlyData
    ?.flatMap((hour) => hour.periodsOutside)
    .filter((period) => period.cameBackAt || period.wentOutAt);
  // Step 2: Convert the keys to the new schema
  const step2 = step1?.map((period) => {
    return {
      doorLocation: "/Hall/Main/Door",
      durationMinutes: period.minutesOutside,
      endAt: period.cameBackAt,
      model: "independence-v1",
      originalEnd: period.cameBackAt,
      originalStart: period.wentOutAt,
      startAt: period.wentOutAt,
      userState: "outside",
    };
  });
  // Step 3: Capture when the user was out overnight: when the first insight only has a cameBackAt
  const step3 = step2?.map((period, index) => {
    if (index === 0 && !period.originalStart) {
      return {
        ...period,
        startAt:
          period.endAt && startOfDay(new Date(period.endAt)).toISOString(),
      };
    }
    return period;
  });
  // Step 4: Capture when the user was out overnight: when the last insight only has a wentOutAt
  const step4 = step3?.map((period, index) => {
    if (index === step3.length - 1 && !period.originalEnd) {
      return {
        ...period,
        endAt:
          period.originalStart &&
          endOfDay(new Date(period.originalStart)).toISOString(),
      };
    }
    return period;
  });
  // Step 5: Combine periods outside that traverse more than one hour segment
  const step5 = step4?.reduce(
    (
      acc: {
        doorLocation: string;
        durationMinutes: number;
        endAt: string | null;
        model: string;
        originalEnd: string | null;
        originalStart: string | null;
        startAt: string | null;
        userState: string;
      }[],
      period
    ) => {
      const lastPeriod = acc[acc.length - 1];
      if (lastPeriod && !lastPeriod.endAt) {
        return [
          ...acc.slice(0, acc.length - 1),
          {
            ...lastPeriod,
            durationMinutes:
              lastPeriod.durationMinutes + period.durationMinutes,
            endAt: period.endAt,
          },
        ];
      }
      return [...acc, period];
    },
    []
  );
  return step5;
};

interface IUseCombineIndependenceAndTimeOutside {
  independenceData: ProcessedDailyIndependenceDataType | undefined;
  independenceDataPrevious: ProcessedDailyIndependenceDataType | undefined;
  timeOutsideData: CombinedContinuousInsightType[];
  timeOutsideDataPrevious: CombinedContinuousInsightType[];
}
export const useCombineIndependenceAndTimeOutside = ({
  independenceData,
  independenceDataPrevious,
  timeOutsideData,
  timeOutsideDataPrevious,
}: IUseCombineIndependenceAndTimeOutside) => {
  const [timeOutsideCombined, setTimeOutsideCombined] = useState<
    CombinedContinuousInsightType[]
  >([]);
  const [timeOutsideCombinedPrevious, setTimeOutsideCombinedPrevious] =
    useState<CombinedContinuousInsightType[]>([]);

  useEffect(() => {
    if (independenceData && timeOutsideData) {
      // Create a Set of dates from timeOutsideData
      const datesInTimeOutside = new Set(
        timeOutsideData.map((day) => day.date)
      );
      // Filter independenceData to only include objects with dates not in timeOutsideData
      const newEntriesFromIndependence = independenceData
        .filter((obj) => !datesInTimeOutside.has(obj.date))
        .map((day) => {
          return {
            activityData: convertHourlyData(day.hourlyData),
            date: day.date,
            isComplete: day.isComplete,
          };
        });
      const mergedData = [
        ...timeOutsideData,
        ...newEntriesFromIndependence,
      ].sort((a, b) => a.date.localeCompare(b.date));
      setTimeOutsideCombined(mergedData);
    } else {
      setTimeOutsideCombined(timeOutsideData);
    }
  }, [timeOutsideData, independenceData]);

  useEffect(() => {
    if (independenceDataPrevious && timeOutsideDataPrevious) {
      // Create a Set of dates from timeOutsideData
      const datesInTimeOutside = new Set(
        timeOutsideDataPrevious.map((day) => day.date)
      );
      // Filter independenceData to only include objects with dates not in timeOutsideData
      const newEntriesFromIndependence = independenceDataPrevious
        .filter((obj) => !datesInTimeOutside.has(obj.date))
        .map((day) => {
          return {
            activityData: convertHourlyData(day.hourlyData),
            date: day.date,
            isComplete: day.isComplete,
          };
        });
      const mergedData = [
        ...timeOutsideDataPrevious,
        ...newEntriesFromIndependence,
      ].sort((a, b) => a.date.localeCompare(b.date));
      setTimeOutsideCombinedPrevious(mergedData);
    } else {
      setTimeOutsideCombinedPrevious(timeOutsideDataPrevious);
    }
  }, [timeOutsideDataPrevious, independenceDataPrevious]);

  return { timeOutsideCombined, timeOutsideCombinedPrevious };
};
