import React, { useState } from "react";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setRefreshOrganisationHubs,
} from "../../State/slices/session-slice";
// Utils
import EditSUProfileForm from "../../Forms/EditSUProfileForm";
import {
  postUserHubAssociation,
  deleteUserHubAssociation,
  postHubProperty,
} from "@intelligentlilli/api-layer";
// hooks
import { useGetUserRole } from "../../Services/Hooks";

const EditServiceUserProfile = ({
  hubId,
  serviceUser,
  hide,
  showRemoveUserModal,
}) => {
  // Local state
  const [updateError, setUpdateError] = useState(null);

  // Redux State
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const staff = useSelector((state) => state.staff);
  const { userIsManagerOrAdmin } = useGetUserRole();

  const initialDateOfBirth = serviceUser?.serviceUserProperties?.dateOfBirth;
  const initialPhoneNumber = serviceUser?.serviceUserProperties?.phoneNumber;
  const initialCareType = serviceUser?.serviceUserProperties?.careType;
  const initialConditions = serviceUser?.serviceUserProperties?.conditions;
  const initialDoctor = serviceUser?.serviceUserProperties?.doctor;
  const initialNextOfKin = serviceUser?.serviceUserProperties?.nextOfKin;
  const initialCareId =
    serviceUser?.serviceUserProperties?.mosaicID ||
    serviceUser?.serviceUserProperties?.careId ||
    serviceUser?.hubProperties?.mosaicId ||
    serviceUser?.hubProperties?.careID ||
    "";
  const initialViewers = serviceUser?.viewers || [];
  const initialMonitoring =
    serviceUser?.serviceUserProperties?.editMonitoring ||
    serviceUser?.serviceUserProperties?.newMonitoring;

  // Handle user updating user details.
  const onUpdateUserInfo = async ({
    dateOfBirth,
    phone,
    careType,
    conditions,
    nextOfKin,
    doctor,
    carerAssignment,
    careId,
    monitoring,
  }) => {
    const promises = [];
    // is dateOfBirth different to the current dateOfBirth?
    if (dateOfBirth !== serviceUser.serviceUserProperties.dateOfBirth) {
      promises.push(
        postHubProperty(server, hubId, "DateOfBirth", dateOfBirth, "web")
      );
    }
    // is phone different to the current phone?
    if (phone !== serviceUser.serviceUserProperties.phoneNumber) {
      promises.push(
        postHubProperty(server, hubId, "PhoneNumber", phone || "", "web")
      );
    }
    // is careType different to the current careType?
    if (careType !== serviceUser?.serviceUserProperties?.careType) {
      promises.push(
        postHubProperty(server, hubId, "CareType", careType || "", "web")
      );
    }
    // is conditions different to the current conditions?
    if (conditions !== serviceUser?.serviceUserProperties?.conditions) {
      promises.push(
        postHubProperty(server, hubId, "Conditions", conditions || "", "web")
      );
    }
    // is nextOfKin different to the current nextOfKin?
    if (nextOfKin !== serviceUser?.serviceUserProperties?.nextOfKin) {
      promises.push(
        postHubProperty(server, hubId, "NextOfKin", nextOfKin || "", "web")
      );
    }
    // is doctor different to the current doctor?
    if (doctor !== serviceUser?.serviceUserProperties?.doctor) {
      promises.push(
        postHubProperty(server, hubId, "Doctor", doctor || "", "web")
      );
    }
    // is careId different to the current careId?
    if (careId !== serviceUser?.serviceUserProperties?.careId) {
      promises.push(
        postHubProperty(server, hubId, "CareId", careId || "", "web")
      );
    }
    let viewersToRemove = [];
    let viewersToAdd = [];

    if (carerAssignment) {
      // What viewers need to be removed from the service user?
      viewersToRemove = initialViewers.filter(
        (viewer) =>
          carerAssignment.find((carer) => carer.id === viewer.id) === undefined
      );
    }
    if (carerAssignment) {
      // What viewers need to be added to the service user?
      viewersToAdd = carerAssignment.filter(
        (carer) =>
          initialViewers.find((viewer) => viewer.id === carer.id) === undefined
      );
    }
    viewersToAdd.forEach((viewer) => {
      promises.push(postUserHubAssociation(server, viewer.id, hubId, "web"));
    });
    viewersToRemove.forEach((viewer) => {
      promises.push(deleteUserHubAssociation(server, viewer.id, hubId, "web"));
    });

    // is careId different to the current careId?
    if (monitoring !== serviceUser?.hubProperties?.editMonitoring) {
      promises.push(
        postHubProperty(
          server,
          hubId,
          "EditMonitoring",
          monitoring || "",
          "web"
        )
      );
    }

    await Promise.all(promises)
      .then(() => {
        dispatch(setRefreshOrganisationHubs(true));
        hide();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log("setHubProperties err:", err);
        setUpdateError(err);
      });
  };

  return (
    <EditSUProfileForm
      updateError={updateError}
      onSubmit={onUpdateUserInfo}
      staff={staff}
      dateOfBirth={initialDateOfBirth}
      phoneNumber={initialPhoneNumber}
      careType={initialCareType}
      conditions={initialConditions}
      doctor={initialDoctor}
      nextOfKin={initialNextOfKin}
      viewers={initialViewers}
      careId={initialCareId}
      userIsManagerOrAdmin={userIsManagerOrAdmin}
      monitoring={initialMonitoring}
      hide={hide}
      showRemoveUserModal={showRemoveUserModal}
    />
  );
};

export default EditServiceUserProfile;
