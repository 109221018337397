import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Hooks
import { useDownloadReport } from "../../Services/Hooks";
// Components
import { useViewport } from "../ViewportProvider";
// Icons
// Utils
import { format, parseISO } from "date-fns";
// API calls
import LilliButton from "Components/DesignSystemComponents/LilliButton/LilliButton";
import { KebabMenuIcon } from "Styles/Icons/DesignSystem";
import { NewDeleteIcon } from "Styles/Icons/DesignSystem/NewDeleteIcon";
import classNames from "classnames";

interface ReportProps {
  report: any;
  setCloseConfirmModal: (value: boolean) => void;
  setReportToDelete: (report: any) => void;
  moveModalUp: boolean;
  isDashboard?: boolean;
}

const Report = ({
  report,
  setCloseConfirmModal,
  setReportToDelete,
  moveModalUp,
  isDashboard,
}: ReportProps) => {
  const navigate = useNavigate();

  const { width } = useViewport();
  const isSmallScreen = width < 1350;
  const isMediumScreen = width < 1530;

  const [showMenu, setShowMenu] = useState(null);

  /* 
    The following isMounted ref makes sure the async call to publish the report does not produce 
    memory leaks should the component be ummounted before the promise resolves
  */

  // Extract required report information to display
  const dateReportWasCreated = report.created_at
    ? format(parseISO(report.created_at), "dd-LL-yy")
    : "";

  const startDate =
    report.start_at && format(parseISO(report.start_at), "dd-LL-yy");

  // We add one less day than than the number of days as the start and end dates are inclusive in the report
  const endDate = report.end_at && format(parseISO(report.end_at), "dd-LL-yy");

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the click event bubbling up to the parent element
    e.stopPropagation();
    setCloseConfirmModal(false);
    setReportToDelete(report);
  };

  const { getButtonText, getButtonIcon, downloadReport, error } =
    useDownloadReport({
      report,
      isSmallScreen: isSmallScreen || isDashboard,
      isMediumScreen,
    });

  const previewReport = () => {
    if (report.report_type === "general-report") {
      navigate(`/pdf-preview/${report.id}`);
    } else {
      navigate(`/pdf-previewV2/${report.request_id}`);
    }
  };

  return (
    <tr
      className={styles.report}
      onClick={() => previewReport()}
      onMouseLeave={() => {
        setShowMenu(null);
      }}
    >
      <td className={`${styles.report_element}`} data-private>
        {report?.userstring}
      </td>
      <td className={`${styles.report_element}`} data-private>
        {report?.title}
      </td>
      <td className={styles.report_element}>{dateReportWasCreated}</td>
      <td className={styles.report_element}>{startDate}</td>
      <td className={styles.report_element}>{endDate}</td>

      <td className={styles.report_element_menu}>
        <div className={styles.report_element_buttons}>
          {isSmallScreen || isDashboard ? (
            <LilliButton
              variant={error ? "warning" : "secondary"}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                downloadReport();
              }}
            >
              {getButtonIcon()}
            </LilliButton>
          ) : (
            <LilliButton
              variant={error ? "warning" : "secondary"}
              leftIcon={getButtonIcon()}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                downloadReport();
              }}
              className={classNames({
                [styles.fixed_width_button]: !isDashboard,
              })}
            >
              {getButtonText()}
            </LilliButton>
          )}
          {error && !isSmallScreen ? (
            <p className={styles.error_message}>
              Report download failed. Please try again later.
            </p>
          ) : null}
        </div>
        <button
          className={showMenu ? styles.kebab_menu_hover : styles.kebab_menu}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(report.request_id || report.id);
          }}
          onMouseOver={() => {
            setShowMenu(report.request_id || report.id);
          }}
        >
          <KebabMenuIcon color={theme.neutral6} />
        </button>
        {showMenu && (
          <div
            className={moveModalUp ? styles.menu_above : styles.menu_below}
            onMouseLeave={() => {
              setShowMenu(null);
            }}
          >
            <LilliButton
              variant="warning"
              onClick={(e) => onDelete(e)}
              leftIcon={<NewDeleteIcon colour={theme.risk6} />}
            >
              Delete report
            </LilliButton>
          </div>
        )}
      </td>
    </tr>
  );
};

export default Report;
