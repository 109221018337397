import React from "react";
import styles from "./styles.module.scss";
import DashboardUsers from "../DashboardUsers";
import DashboardStaff from "../DashboardStaff";
import DashboardReports from "../DashboardReports";
import DashboardFriendsAndFamily from "../DashboardFriendsAndFamily";

interface DashboardSearchResultsProps {
  selectedFilter: string;
  selectedTab: string;
  searchValue: string;
}

const DashboardSearchResults = ({
  selectedFilter,
  selectedTab,
  searchValue,
}: DashboardSearchResultsProps) => {
  return (
    <div className={styles.dashboard_content}>
      {selectedTab === "users" && (
        <DashboardUsers
          searchValue={searchValue}
          selectedFilter={selectedFilter}
        />
      )}
      {selectedTab === "staff" && <DashboardStaff searchValue={searchValue} />}
      {selectedTab === "friends & family" && (
        <DashboardFriendsAndFamily searchValue={searchValue} />
      )}
      {selectedTab === "reports" && (
        <DashboardReports searchValue={searchValue} />
      )}
    </div>
  );
};

export default DashboardSearchResults;
