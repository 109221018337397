import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import {
  postHubAssignDevice,
  postHubProperty,
  // postDecommissionHub,
} from "@intelligentlilli/api-layer";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
import {
  updateServiceUser,
  updateServiceUserProperties,
} from "../../../State/slices/serviceUsers-slice";
// Hooks
import { useNativeInstallAuth } from "../../../Services/Hooks";
// Utils
import { getInitials } from "@intelligentlilli/lilli-utils";
// Components
import { ManuallyEnterQRCode, ScanQRCode } from "../../../Components/InstallQR";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallLoadingBubble from "../../../Components/InstallLoadingBubble";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import VideoPlayer from "../../../Components/VideoPlayer";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";


type InstallLinkDeviceProps = { 
  isWeb: boolean; 
  goBackLink: string; 
  baseURL: string; 
}

// Unified InstallLinkDevice component for Web and Mobile (via WebView)
const InstallLinkDevice = ({ isWeb, goBackLink, baseURL }: InstallLinkDeviceProps) => {
  const { id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const origin = isWeb ? "web" : "mobile-app";

  // sizes
  const { width } = useViewport();
  const isMobile = width <= 600;
  const isBiggerThan500 = width > 500;
  const isLessEqualTo900px = width <= 900;

  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i: { id: number }) => i.id === parseInt(id));
  const indexOfSU = serviceUsers.findIndex(
    (serviceUser) => serviceUser.id.toString() === id
  );
  const selectedSUName = selectedSuObject?.[0]?.userstring || "user";
  const serviceUserInitials = getInitials(selectedSUName) || "";

  // Local state
  const [devicestringError, setDevicestringError] = useState();
  const [stage, setStage] = useState("start");
  const [deviceId, setDeviceId] = useState<string | false>(false);
  const [brokenQRCode, setBrokenQRCode] = useState(false);

  // redux state
  const server = useSelector((state) => state.session.server);

  // Set cookie headeres for native install
  useNativeInstallAuth({ path: "install/native/link" });

  const onLinkDevice = async (deviceId: string) => {
    try {
      setStage("loading");
      if (selectedSuObject?.[0]?.devicestring) {
        // await postDecommissionHub(server, id, origin);
      }
      const res = await postHubAssignDevice(server, id, deviceId, origin);
      if (res.ok) {
        setStage("success");
        setDeviceId(deviceId);
        await postHubProperty(
          server,
          id,
          "InstallationStatus",
          "started",
          origin
        );
      } else {
        setDevicestringError(res.body.error);
        setStage("fail");
        if (res.status === 401) {
          navigate("/login"); // TO DO: where to kick back native user?
          // IMPORTANT: Do not remove or amend this console log as the native app depends on it
          console.log("Signal to native app -  error on linkingDevice: 401");
        }
      }
    } catch (error) {
      setStage("fail");
      setDeviceId(false);
    }
  };

  return (
    <InstallationCard
      className={`${
        stage === "start"
          ? styles.start_screen_flex_direction
          : styles.flex_direction
      }`}
    >
      {stage === "start" && ( // "I.e. Just arrived onto the page, has not attempted a link"
        <div>
          <div className={styles.header}>
            <h2>Connect to Lilli kit</h2>
            <p>Scan the QR code on the bottom of the Lilli kit</p>
          </div>
          <div className={styles.start}>
            <div className={styles.card_left}>
              <VideoPlayer
                src={"/videos/QR_code.mp4"}
                shouldLoop={false}
                videoType={"video/mp4"}
                fallbackImage={"/images/installation/deviceId.png"}
                imageAlt={"QR code sticker with 16 digits"}
              />
              {isLessEqualTo900px && (
                <div className={styles.qr_code_left_wrapper}>
                  <ScanQRCode
                    devicestringError={devicestringError}
                    onLinkDevice={onLinkDevice}
                  />
                </div>
              )}
            </div>
            <div className={styles.card_right}>
              <div className={styles.qr_code_right_wrapper}>
                {!isLessEqualTo900px && (
                  <ScanQRCode
                    devicestringError={devicestringError}
                    onLinkDevice={onLinkDevice}
                  />
                )}
              </div>
              <ManuallyEnterQRCode
                brokenQRCode={brokenQRCode}
                setBrokenQRCode={setBrokenQRCode}
                onLinkDevice={onLinkDevice}
                devicestringError={devicestringError}
                buttonClass={styles.buttons}
                isDesktop={isBiggerThan500}
              />
            </div>
          </div>
          <div className={styles.install_flow_buttons}>
            <InstallationGoBackButton
              label={"Exit install"}
              onClick={() => navigate(goBackLink)}
              isDesktop={!isMobile}
            />
            <InstallationNextButton
              label="Next"
              isDesktop={!isMobile}
              type="submit"
              onClick={() => {
                // as the form is separate from the submit button, further down the DOM tree, you have to submit the form this way: https://final-form.org/docs/react-final-form/faq
                document
                  .getElementById("qr-code-install")
                  .dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                  );
              }}
            />
          </div>
        </div>
      )}
      {stage === "loading" && (
        <div className={styles.qr_loading_container}>
          <div>
            <h2 className={styles.connecting_user_to_hub}>Connecting...</h2>
            <p>Please wait while we assign this Lilli user to the Lilli kit.</p>
          </div>
          <InstallLoadingBubble initials={serviceUserInitials} />
          <InstallationGoBackButton
            label="Cancel"
            onClick={() => navigate(goBackLink)}
            isDesktop={!isMobile}
            marginTop={"24px"}
          />
        </div>
      )}
      {stage === "success" && (
        <div className={styles.card_right}>
          <InstallationSplash
            icon={<ThumbsUpIcon color={theme.expected2} />}
            headers={[
              "Great!",
              `${selectedSUName || "This user"} is assigned to the Lilli kit.`,
            ]}
            message={["Let's get your hub connected"]}
            goBackButtonLabel={"Exit install"}
            goBackOnClick={() => navigate(goBackLink)}
            nextButtonLabel={"Continue"}
            nextButtonOnClick={() => {
              dispatch(
                updateServiceUserProperties({
                  index: indexOfSU,
                  key: "installationStatus",
                  value: "started",
                })
              );
              dispatch(
                updateServiceUser({
                  index: indexOfSU,
                  key: "devicestring",
                  value: `${deviceId}`,
                })
              );
              navigate(`${baseURL}/hub/${id}/${deviceId}`);
            }}
            isDesktop={!isMobile}
          />
        </div>
      )}
      {stage === "fail" && (
        <div className={styles.card_right}>
          <InstallationSplash
            icon={<ThumbsDownIcon color={theme.risk3} />}
            headers={["Uh-oh!", "Something went wrong"]}
            message={[
              <>
                We could not assign{" "}
                <span data-private>{selectedSUName || "this user"}</span> to the
                Lilli kit. Let's try again, but if the problem continues,
                contact +44 (0)758 800 8527 or email
              </>,
            ]}
            email={"support@intelligentlilli.com"}
            goBackButtonLabel={"Exit install"}
            goBackOnClick={() => {
              dispatch(setRefreshUser(true));
              dispatch(setRefreshOrganisationHubs(true));
              navigate(goBackLink);
            }}
            nextButtonLabel={"Try again"}
            nextButtonOnClick={() => setStage("start")}
            isDesktop={!isMobile}
          />
        </div>
      )}
    </InstallationCard>
  );
};

export default InstallLinkDevice;
