import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
// Components
import { CareIcon, ConditionsIcon } from "../../Styles/Icons/DesignSystem";

const ServiceUserData = (props) => {
  const { width } = useViewport();
  const isDesktop = width > 600;

  const rawCareType = props.serviceUser?.serviceUserProperties?.careType;
  const rawConditions = props.serviceUser?.serviceUserProperties?.conditions;

  const hasConditions = !!rawConditions;
  const hasCareType = !!rawCareType;

  const conditions = hasConditions ? rawConditions?.split(", ") : [];
  const careType = hasCareType ? rawCareType?.split(", ") : [];

  return (
    <div className={styles.data} style={props.style}>
      <div className={styles.service_user_data}>
        <div className={styles.data_item}>
          <ConditionsIcon />
          {isDesktop && `Conditions: ${!hasConditions ? "Not set" : ""}`}
          {hasConditions && (
            <div className={styles.conditions}>
              {conditions.map((condition, index) => {
                return (
                  <div className={styles.condition} key={index}>
                    {condition}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className={styles.data_item}>
          <CareIcon />
          {isDesktop && `Care Type: ${!hasCareType ? "Not set" : ""}`}
          {hasCareType && (
            <div className={styles.conditions}>
              {careType.map((caretype, index) => {
                return (
                  <div className={styles.care_type} key={index}>
                    {caretype}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ServiceUserData.propTypes = {
  serviceUser: PropTypes.object,
  iconSize: PropTypes.object,
};

export default ServiceUserData;
