import React, { useState } from "react";
import { useViewport } from "../ViewportProvider";
// Styles
import styles from "./styles.module.scss";
import { getFormattedChartData } from "../../Services/Utils/getFormattedChartData";
// Components
import VictoryFlightpathChart from "../../Charts/VictoryFlightpathChart";
import DayIndependence from "../../Charts/DayIndependence";
import { InfoIcon } from "../../Styles/Icons/DesignSystem";
import SummaryWeekMonth from "../SummaryWeekMonth";
import { CombinedContinuousInsightType } from "@intelligentlilli/lilli-utils";

interface BehaviourIndependenceProps {
  independenceData: CombinedContinuousInsightType[];
  title: string;
  dateType: string;
  independenceDataPrevious: CombinedContinuousInsightType[];
  activeTab: {
    tab: string;
  };
  changePage: (
    activeTabs?: {
      tab: string;
    },
    endDate?: Date,
    startDate?: Date,
    dateType?: string
  ) => void;
}
const BehaviourIndependence = ({
  independenceData,
  title,
  dateType,
  independenceDataPrevious,
  activeTab,
  changePage,
}: BehaviourIndependenceProps) => {
  // local state
  const [showingTooltip, setShowingTooltip] = useState(false);

  // Responsive design
  const { width } = useViewport();
  /* 
  The following section does the required data manipulation
  */
  // Bool to indicate whether we have data or not
  const hasData = independenceData?.length > 0;
  const outsideDataDayView =
    dateType === "day" &&
    independenceData[0]?.activityData
      ?.filter((activity) => activity.userState === "outside")
      .map((activity) => ({
        x: activity.endAt,
        x0: activity.startAt,
        value: activity.durationMinutes,
      }));

  const timeOutside =
    independenceData?.map((day) => ({
      ...day,
      ts: day.date,
      value: day.activityData
        .filter((activity) => activity.userState === "outside")
        .reduce((total, activity) => total + activity.durationMinutes, 0),
    })) || [];

  const timeOutsidePrevious =
    independenceDataPrevious?.map((day) => ({
      ...day,
      ts: day.date,
      value: day.activityData
        .filter((activity) => activity.userState === "outside")
        .reduce((total, activity) => total + activity.durationMinutes, 0),
    })) || [];

  const outData = outsideDataDayView || timeOutside;
  // Get the max value for the time outside. This is used to decide whether to convert the values to hours rather than minutes for readability in the graph
  const maxValue = Math.max(...timeOutside.map((d) => d.value));

  const convertToHours = maxValue / 60 > 1;

  const flightpathData = convertToHours
    ? timeOutside.map((day) => ({
        ...day,
        value: day.value !== null ? day.value / 60 : null,
        hourUnits: true,
        hours: Math.floor(day.value / 60),
        minutes: Math.round(day.value % 60),
      }))
    : timeOutside;

  const formattedChartData =
    hasData && dateType !== "day"
      ? getFormattedChartData(flightpathData, dateType, "independence")
      : [];
  /*-----End------*/

  /* 
  The following section generates the summary stats that are displayed above the charts 
  */
  // Get the total duration outside in minutes
  const minutesOutside = outData?.reduce((total, val) => total + val?.value, 0);
  const showHoursAndMinutes = minutesOutside >= 60;
  const durationOutsideHours = Math.floor(minutesOutside / 60);
  const durationOutsideRemainder = minutesOutside - 60 * durationOutsideHours;

  // Get the total duration outside the previous period
  const minOutsidePrev = timeOutsidePrevious?.reduce(
    (total, val) => total + val?.value,
    0
  );
  const prevShowHoursAndMinutes = minOutsidePrev >= 60;
  const prevDurationOutsideHours = Math.floor(minOutsidePrev / 60);
  const prevDurationOutsideRemainder =
    minOutsidePrev - 60 * prevDurationOutsideHours;

  // Get the days with no time outside for the week view
  const noTimeOutside = outData?.filter((day) => day.value === 0).length;

  // Our summary section has the same first stat regardless of whether we are on day/week/month view
  const firstSummaryStat: {
    value: number;
    label: string;
    units?: string;
    value2?: number | null;
    units2?: string | null;
  } = {
    value: showHoursAndMinutes ? durationOutsideHours : minutesOutside,
    units: showHoursAndMinutes ? "Hrs" : "min",
    value2: showHoursAndMinutes ? durationOutsideRemainder : null,
    units2: showHoursAndMinutes ? "min" : null,
    label: "Total time outside",
  };

  // Pull together all the summary stats for week/month view
  const summaryStatsWeekMonth = [firstSummaryStat];
  // This feels a little hacky but essentially we are only adding this third summary stat when there's room to do so.
  if (width < 850 || width > 1200) {
    summaryStatsWeekMonth.push({
      value: noTimeOutside,
      label: "Day(s) with no time outside",
    });
  }

  // Pull together all the summary stats for day view
  const summaryStatsDay = [
    firstSummaryStat,
    {
      value: prevShowHoursAndMinutes
        ? prevDurationOutsideHours
        : minOutsidePrev,
      value2: prevShowHoursAndMinutes ? prevDurationOutsideRemainder : null,
      units: prevShowHoursAndMinutes ? "Hrs" : "min",
      units2: prevShowHoursAndMinutes ? "min" : null,
      label: "Previous day",
    },
  ];

  const INDEPENDENCE_TEXT =
    "Time outside measures the time spent outside of the home using the front door sensor plus in-home movements to determine if the service user is at home or out and about.";
  /*-----End------*/

  return (
    <div className={styles.behaviour}>
      <div className={styles.behaviour_context}>
        <h1>{title}</h1>
        <div className={styles.behaviour_explanation}>{INDEPENDENCE_TEXT}</div>
        {dateType === "day" && hasData && (
          <div>
            <h2 className={styles.dayDetails}>Details</h2>
            <div className={styles.dayDetails_info}>
              <InfoIcon />
              <div>Hover over time blocks for details</div>
            </div>
          </div>
        )}
      </div>

      {/* ----- BEHAVIOUR CHART for week/month ----- */}
      {dateType !== "day" && hasData && (
        <div className={styles.behaviour_chart}>
          {!showingTooltip && (
            <div className={styles.chartSummary}>
              <SummaryWeekMonth
                behaviour={"independence"}
                data={summaryStatsWeekMonth}
                small
              />
            </div>
          )}
          <VictoryFlightpathChart
            data={formattedChartData}
            behaviour={"independence"}
            showingTooltip={showingTooltip}
            setShowingTooltip={setShowingTooltip}
            dateType={dateType}
            yAxisTitle={`Time outside (${convertToHours ? "hrs" : "mins"})`}
            changePage={changePage}
            activeTab={activeTab}
          />
        </div>
      )}

      {/* The chart won't show up if there is an empty array for 'went out' and 'came back' */}
      {dateType === "day" && hasData && (
        <div className={styles.behaviour_chart}>
          <div className={styles.chartSummary}>
            <SummaryWeekMonth
              behaviour={"independence"}
              data={summaryStatsDay}
              small
            />
          </div>

          <div className={styles.dayChart}>
            <DayIndependence outData={outData} />
          </div>
        </div>
      )}
      {/* Show a no data message where there's data on either day or week/month view */}
      {!hasData && (
        <div className={styles.behaviour_chart}>
          <h2 className={styles.behaviour_noData}>No data</h2>
        </div>
      )}
    </div>
  );
};

export default BehaviourIndependence;
