import React from "react";
import theme from "../../theme.scss";
import { IconProps } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

export const NewDeleteIcon = ({ width, colour }: IconProps) => {
  return (
    <FontAwesomeIcon
      icon={faTrashCan}
      fontSize={width ?? "16px"}
      color={colour ?? theme.neutral7}
    />
  );
};
