import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Skeleton from "../Skeleton";
import { SensorStatus } from "Services/Utils/newDeviceMonitoring/types";

interface SensorStatusTagProps {
  sensorStatusLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sensorStatus: SensorStatus;
  isHovered?: boolean;
}

const SensorStatusTag = ({
  sensorStatusLoading,
  sensorStatus,
  onClick,
  isHovered,
}: SensorStatusTagProps) => {
  if (sensorStatusLoading) {
    return <Skeleton width="100px" />;
  }

  return (
    <div
      onClick={onClick}
      className={classNames(styles.sensor_status_tag, {
        [styles.online]:
          sensorStatus.variant === "online" ||
          sensorStatus.variant === "warning",
        [styles.offline]: sensorStatus.variant === "offline",
        [styles.error]: sensorStatus.variant === "error",
        [styles.lowBattery]: sensorStatus.variant === "lowBattery",
        [styles.hovered]: isHovered,
        [styles.noHover]: !onClick,
      })}
    >
      {sensorStatus.leftIcon ? (
        <div className={styles.icon}>{sensorStatus.leftIcon}</div>
      ) : null}
      <p className={styles.label}>{sensorStatus.label}</p>
    </div>
  );
};

export default SensorStatusTag;
