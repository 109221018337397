import {
  setHours,
  parseISO,
  max,
  min,
  isBefore,
  differenceInMinutes,
} from "date-fns";
import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";

export const getTimeOutsideDuringTheDay = (independenceData, userString) => {
  const tenPM =
    independenceData[0]?.date &&
    setHours(new Date(independenceData[0].date), 22);
  const sixAM =
    independenceData[0]?.date &&
    setHours(new Date(independenceData[0].date), 6);

  const activitiesOutside = independenceData[0]?.activityData.filter(
    (activity) => activity?.userState === "outside"
  );
  const minutesOutside = activitiesOutside?.reduce((totalMinutes, activity) => {
    const activityStart = parseISO(activity.startAt);
    const activityEnd = parseISO(activity.endAt);

    // Get the actual overlap period
    const overlapStart = max([activityStart, sixAM]); // Latest start time
    const overlapEnd = min([activityEnd, tenPM]); // Earliest end time

    // Only count minutes if the activity falls within the range
    if (isBefore(overlapStart, overlapEnd)) {
      totalMinutes += differenceInMinutes(overlapEnd, overlapStart);
    }
    return totalMinutes;
  }, 0);

  // convert the time outside at night to a string
  const timeOutsideDuringTheDayAsString = getHoursAndMinutesAsString(
    minutesOutside,
    false
  );
  const didNotLeaveTheHouse = minutesOutside === 0;

  const dataPresent = !!independenceData;
  if (!dataPresent) {
    return {
      offline: true,
      title: "Time outside at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    title: "Time outside during the day",
    headline: timeOutsideDuringTheDayAsString,
    icon: "INDEPENDENCE-DAY",
    context: didNotLeaveTheHouse
      ? `Since 6am today, ${userString} did not leave the house.`
      : `Since 6am today, ${userString} left the house for ${timeOutsideDuringTheDayAsString}.`,
  };
};
