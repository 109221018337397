import React from "react";
import styles from "../styles.module.scss";
import classNames from "classnames";
import LilliSelect from "../../LilliDesignSystem/LilliSelect";
import format from "date-fns/format";
import { decommissionReasonsFallback } from "@intelligentlilli/api-layer";

const RemoveServiceUserForm = ({
  serviceUser,
  reason,
  setReason,
  outcome,
  setOutcome,
  reasonText,
  setReasonText,
  offboardingReasonsData,
}) => {
  const formatReasons = (reasonsObject) => {
    return Object.entries(reasonsObject).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  };

  // add fallback in case api fails
  const reasonValues = formatReasons(
    offboardingReasonsData ?? decommissionReasonsFallback
  );

  // so other appears at the end of the dropdown
  const sortedReasonValues = reasonValues.sort((a, b) => {
    if (a.value === "other") return 1; // Move "other" to the end
    if (b.value === "other") return -1; // Keep "other" at the bottom
    return 0; // Keep the rest of the order intact
  });

  const outcomeValues = [
    {
      label: "Improved",
      value: "improved",
    },
    {
      label: "Deteriorated",
      value: "deteriorated",
    },
    {
      label: "Stayed the same",
      value: "stable",
    },
  ];

  return (
    <div>
      <p className={styles.label}>
        We need to capture some information about {serviceUser.userstring}’s
        time with Lilli, please let us know the following information:
      </p>
      <div className={styles.container}>
        <div className={styles.field}>
          <div className={classNames(styles.label, styles.bold)}>
            What is the reason for removing this user from Lilli?
            <span className={styles.super}>*</span>
          </div>
          <LilliSelect
            withinModal
            placeholder="Select a reason"
            options={sortedReasonValues}
            onChange={(selectedOption) => setReason(selectedOption)}
            value={reason}
          />
          {reason?.value === "other" ? (
            <div className={styles.field}>
              <div className={classNames(styles.label, styles.bold)}>
                Please specify your reason below
              </div>
              <input
                data-private
                className={styles.input}
                type="text"
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
                placeholder="Type your reason here"
              />
            </div>
          ) : null}
        </div>
        <div className={styles.field}>
          <div className={classNames(styles.label, styles.bold)}>
            Did this user’s condition improve or deteriorate since the Lilli kit
            was installed on {format(new Date(serviceUser.ts), "dd/MM/yy")}?
            <span className={styles.super}>*</span>
          </div>
          <LilliSelect
            withinModal
            placeholder="Select an option"
            options={outcomeValues}
            onChange={(selectedOption) => setOutcome(selectedOption)}
            value={outcome}
          />
        </div>
      </div>
    </div>
  );
};

export default RemoveServiceUserForm;
