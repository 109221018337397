import { Roles } from "@intelligentlilli/api-layer";
import {
  BookIcon,
  DashboardIcon,
  FAQIcon,
  InstallIcon,
  ReportsIcon,
  SettingsIcon,
  StaffIcon,
  UsersIcon,
} from "Styles/Icons/DesignSystem";

type NavType =
  | "DASHBOARD"
  | "Community"
  | "LILLI_USERS"
  | "STAFF_MEMBERS"
  | "INSTALL"
  | "FAQS"
  | "Settings"
  | "REPORTS";

export interface NavLinkType {
  item: {
    link: string;
    name: string;
    type: NavType;
    ariaLabel: string;
  };
}

// Assign icons to the different navigation routes
export const NavTypeIcon = {
  DASHBOARD: DashboardIcon,
  LILLI_USERS: UsersIcon,
  STAFF_MEMBERS: StaffIcon,
  INSTALL: InstallIcon,
  REPORTS: ReportsIcon, // looks like a document
  FAQS: FAQIcon,
  Settings: SettingsIcon,
  Community: BookIcon,
};

// A should highlight utility function to determine whether a link should be highlighted
export const shouldHighlight = (
  item: NavLinkType["item"],
  currentRoute: string
) => {
  // We want the link to be highlighted if we're on that exact page or that we're on a subsequent page eg /staff/id or /lilli-users/id
  return (
    item.link === currentRoute ||
    // Have to exclude '/' as by default it appears in all routes
    (currentRoute.includes(item.link) && item.link !== "/")
  );
};

// A function to return the correct navigation links for any user role
export const getNavigationItems = (
  rolesArray: Roles[],
  showReports: boolean
) => {
  // These are the different navigation options depending on whether the user is logged out, a manager, or a staff member or an installer
  // The following if statements build up the correct nav links for the current user type
  let navigationItems = [];

  // Base navigation for a member of an organisation
  if (
    rolesArray?.includes("admin") ||
    rolesArray?.includes("manager") ||
    rolesArray?.includes("user")
  ) {
    navigationItems.push({
      link: "/",
      name: "Dashboard",
      type: "DASHBOARD",
      ariaLabel: "Dashboard page",
    });
  }

  // Everyone can see the SU list page
  if (
    rolesArray?.includes("admin") ||
    rolesArray?.includes("manager") ||
    rolesArray?.includes("user") ||
    rolesArray?.includes("installer")
  ) {
    navigationItems.push({
      link: "/lilli-users",
      name: "Lilli users",
      type: "LILLI_USERS",
      ariaLabel: "Lilli users page",
    });
  }

  // Everyone except Installers cans see Reports, Staff, and Settings page
  if (
    (rolesArray?.includes("admin") ||
      rolesArray?.includes("manager") ||
      rolesArray?.includes("user")) &&
    showReports
  ) {
    navigationItems.push({
      link: "/reports",
      name: "Reports",
      type: "REPORTS",
      ariaLabel: "Reports page",
    });
    navigationItems.push({
      link: "/staff",
      name: "Staff members",
      type: "STAFF_MEMBERS",
      ariaLabel: "Staff members page",
    });
    navigationItems.push({
      link: "/settings",
      name: "Settings",
      type: "Settings",
      ariaLabel: "Settings page",
    });
  }

  // Only Installers see the install page
  if (rolesArray?.includes("installer")) {
    navigationItems.push({
      link: "/install",
      name: "Install",
      type: "INSTALL",
      ariaLabel: "Installation page",
    });
  }

  navigationItems.push({
    link: "/faqs",
    name: "FAQs",
    type: "FAQS",
    ariaLabel: "FAQs page",
  });

  return navigationItems;
};
