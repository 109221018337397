import {
  setHours,
  parseISO,
  max,
  min,
  isBefore,
  differenceInMinutes,
} from "date-fns";
import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";

export const getTimeOutsideAtNight = (
  independenceData,
  independenceDataPrevious,
  userString
) => {
  const allActivities = [
    ...(independenceData[0]?.activityData || []),
    ...(independenceDataPrevious[0]?.activityData || []),
  ].filter((activity) => activity?.userState === "outside");

  // peice together 10pm to 6am data using yesterday and today's data
  const tenPM =
    independenceDataPrevious[0]?.date &&
    setHours(new Date(independenceDataPrevious[0].date), 22);
  const sixAM =
    independenceData[0]?.date &&
    setHours(new Date(independenceData[0].date), 6);

  const minutesOutsideAtNight = allActivities.reduce(
    (totalMinutes, activity) => {
      const activityStart = parseISO(activity.startAt);
      const activityEnd = parseISO(activity.endAt);

      // Get the actual overlap period
      const overlapStart = max([activityStart, tenPM]); // Latest start time
      const overlapEnd = min([activityEnd, sixAM]); // Earliest end time

      // Only count minutes if the activity falls within the range
      if (isBefore(overlapStart, overlapEnd)) {
        totalMinutes += differenceInMinutes(overlapEnd, overlapStart);
      }
      return totalMinutes;
    },
    0
  );
  // convert the time outside at night to a string
  const timeOutsideAtNightString = getHoursAndMinutesAsString(
    minutesOutsideAtNight,
    false
  );

  const didNotLeaveTheHouse = minutesOutsideAtNight === 0;

  const dataPresent = !!independenceData;
  if (!dataPresent) {
    return {
      offline: true,
      title: "Time outside at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    title: "Time outside at night",
    headline: timeOutsideAtNightString,
    icon: "INDEPENDENCE-NIGHT",
    context: didNotLeaveTheHouse
      ? `Between 10pm yesterday and 6am today, ${userString} did not leave the house.`
      : `Between 10pm yesterday and 6am today, ${userString} left the house for ${timeOutsideAtNightString}.`,
  };
};
