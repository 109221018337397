import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDevelcoGatewaySensors, DeviceType } from "@intelligentlilli/api-layer";
import { setSensors } from "../../State/slices/sensors-slice";
import { INITAL_SENSORS } from "../Constants";
import { useNavigate } from "react-router-dom";

type GetSensorStatusProps = { 
  devicestring: string | undefined; 
  id: string | undefined;  
  continuous?: boolean;
  isNativeInstall: boolean;
}

export const useGetSensorStatus = ({
  devicestring,
  id,
  continuous = false,
  isNativeInstall,
}: GetSensorStatusProps) => {
  // redux state
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const sensors = useSelector((state) => state.sensors) || INITAL_SENSORS;

  const navigate = useNavigate();

  // local state
  const [error, setError] = useState<string | false>(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DeviceType[]>();

  const hubIsOnline = sensors?.hub?.online;

  // Create a callback function that fetches the sensor status from a device
  const fetchSensorStatus = useCallback(
    (callback?: () => void) => {
      setLoading(true);
      setError(false);
      getDevelcoGatewaySensors(server, devicestring || "", "web")
        .then((res) => {
          if (res.ok && res.body?.data) {
            setError(false);
            setLoading(false);
            setData(res.body?.data);
            typeof callback === "function" && callback();
          } else {
            if (!isNativeInstall && res.status === 401) {
              navigate("/login");
            }
            console.log("getDevelcoGatewaySensors NOT OK, status:", res.status, "res.body:", res?.body);
            setLoading(false);
            setError("Could not detect sensors.");
          }
        })
        .catch((err) => {
          console.log("getDevelcoGatewaySensors ERROR  block:", err);
          setLoading(false);
          setError("Sorry, we could not connect the sensor(s) to the hub.");
        });
    },
    [isNativeInstall, devicestring, server, navigate]
  );

  // Upon load fetch the sensor status
  useEffect(() => {
    if (continuous) {
      fetchSensorStatus();
    }
    
    // For pages that need to continuously poll the hub (while waiting for it to be provisioned) add that here
    let interval: number | undefined;
    if (continuous && !hubIsOnline) {
      interval = window.setInterval(() => {
        fetchSensorStatus();
      }, 15000); // 15 seconds
    }

    // Clean up when the effect is re-run or unmounted
    return () => {
      if (interval !== undefined) {
        window.clearInterval(interval);
      }
    }; 
  }, [continuous, fetchSensorStatus, hubIsOnline]);

  // Hook listening for changes in the data and persisting them to state
  useEffect(() => {
    // Sometimes the response comes back as updating the hub. Ignore these responses as they give no information
    if (data && data.length > 0 && data[0]?.name !== "update") {
      // Save the results in redux
      console.log("-- dispatching new sensor results -- ");
      dispatch(
        setSensors({
          devices: data,
          hubId: id,
          devicestring: devicestring,
        })
      );
    }
  }, [data, dispatch, id, devicestring]);

  return { loading, fetchSensorStatus, error, hubIsOnline, sensors };
};
