import React from "react";
import theme from "../../theme.scss";
import { IconProps } from "./types";

export const BackIcon = ({ width, colour }: IconProps) => {
  return (
    <svg
      width={width || 16}
      height={width || 17}
      viewBox="0 0 16 17"
      fill="none"
      id="BackIcon"
    >
      <g>
        <path
          d="M1.82093 9.82593L3.97118 11.9983C4.18735 12.2163 4.53896 12.2176 4.75695 12.0014C4.75799 12.0004 4.75904 11.9994 4.76008 11.9983C4.97807 11.7821 4.97938 11.4305 4.76321 11.2126C4.76217 11.2115 4.76112 11.2105 4.76008 11.2094L2.78198 9.22039H14.1112C14.418 9.22039 14.6667 8.97166 14.6667 8.66485C14.6667 8.35805 14.418 8.10932 14.1112 8.10932H2.74865L4.76008 6.0979C4.96479 5.88329 4.96479 5.54574 4.76008 5.33113C4.54391 5.11313 4.19231 5.11183 3.97431 5.328C3.97327 5.32904 3.97223 5.33009 3.97118 5.33113L1.82093 7.47018C1.17086 8.12103 1.17086 9.17533 1.82093 9.82593Z"
          fill={colour || theme.neutral5}
        />
      </g>
    </svg>
  );
};
