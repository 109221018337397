import React from "react";
import styles from "./styles.module.scss";

interface LineProps {
  className?: string;
}

const Line = ({ className }: LineProps) => {
  return <div className={`${styles.line} ${className}`} />;
};

export default Line;
