import LilliButton from "Components/DesignSystemComponents/LilliButton/LilliButton";
import styles from "./styles.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "State/hooks";

const ErrorFallback = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const resetError = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className={styles.error_page}>
      <div className={styles.error_page_content}>
        <img height="53px" src="/logo/lilli-logo-teal.svg" alt="Lilli" />
        <h1 className={styles.heading}>Uh oh, something went wrong</h1>
        <p>
          The app has a problem and couldn’t load your request. We apologise for
          this. Please contact Lilli support if the issue continues on +44
          (0)758 800 8527 or{" "}
          <a
            className={styles.email}
            href="mailto: support@intelligentlilli.com"
            target="_blank"
            rel="noreferrer"
          >
            support@intelligentlilli.com
          </a>
        </p>
        {user ? (
          <LilliButton onClick={resetError} variant="primary">
            Back to dashboard
          </LilliButton>
        ) : null}
      </div>
    </div>
  );
};

export default ErrorFallback;
