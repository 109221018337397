import React from "react";
import styles from "./styles.module.scss";
import { Form } from "react-final-form";
// Components
import FormError from "../../Components/FormError";
import PrimaryButton from "../../Components/PrimaryButton";
import OneTimePasscode from "../../Components/OneTimePasscode";
import SecondaryButton from "../../Components/SecondaryButton";
// Notes:
// "meta" refers to the properties related to the form
// "meta.error" are the form related errors (i.e. validation)
// "meta.touched" means the input field has been interacted with
// "props.error" are things like API response error messages

const TwoFAForm = ({
  error,
  onSubmit,
  username,
  password,
  show2FAMessage,
  setShow2FA,
}) => {
  const message =
    "We’ve sent a 6 digit code to your email address. Please enter this code below.";
  const TWOFA_HELP = `mailto: support@intelligentlilli.com &subject=${username}: Help Needed - Two-Factor Authentication&body=Hi, I'm having trouble with two-factor authentication. Thanks, ${username}}`;

  return (
    <Form
      onSubmit={(values) => {
        const sixDigitToken = values?.token?.join("");
        onSubmit({
          username,
          password,
          token: sixDigitToken,
        });
      }}
      validate={(values) => {
        const token = values?.token?.join("");
        const errors = {};
        if (!token || token?.length < 6) {
          errors.token = "6-digit token is required";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.h1}>
          <div className={styles.twofa_message}>{message}</div>
          <OneTimePasscode fieldName="token" />
          <div className={styles.error_messages}>
            {show2FAMessage && (
              <FormError error={show2FAMessage} className={styles.error} />
            )}
            {error && <FormError error={error} className={styles.error} />}
          </div>
          <div className={styles.bottom_buttons}>
            <SecondaryButton
              style={{ flex: 1, marginTop: "10px", width: "100%" }}
              onClick={() => window.open(TWOFA_HELP, "_blank")}
              className={styles.help_button}
            >
              Having trouble?
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              disabled={submitting || error}
              style={{
                width: "100%",
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: 500,
                transition: "none",
              }}
              onClick={() => handleSubmit}
            >
              {"Submit"}
            </PrimaryButton>
            <SecondaryButton
              style={{
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: 500,
                transition: "none",
                width: "100%",
              }}
              onClick={() => setShow2FA(false)}
              className={styles.help_button}
            >
              {"Back to login"}
            </SecondaryButton>
          </div>
        </form>
      )}
    />
  );
};

export default TwoFAForm;
