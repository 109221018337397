import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconProps } from "./types";
import theme from "Styles/theme.scss";

const NewCopyIcon = ({ width, colour }: IconProps) => {
  return (
    <FontAwesomeIcon
      icon={faCopy}
      color={colour ?? theme.neutral7}
      fontSize={width ?? "16px"}
    />
  );
};

export default NewCopyIcon;
