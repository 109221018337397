import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// Components
import Page from "../../Components/Page";
import GuestPage from "../../Components/GuestPage";
import SecondaryButton from "../../Components/SecondaryButton";
import { NavigateBeforeIcon } from "../../Styles/Icons";
import PageTitle from "../../Components/PageTitle";
import { useAppSelector } from "State/hooks";

const FAQContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="Frequently asked questions" />

      <div className={styles.questions}>
        <details className={styles.details}>
          <summary>
            <h3>General </h3>
          </summary>
          <dl>
            <dt className={styles.question}>
              How do I log in to the Lilli web app?
            </dt>
            <dd className={styles.answer}>
              The app is available at{" "}
              <a href="app.intelligentlilli.com">
                https://app.intelligentlilli.com
              </a>
            </dd>

            <dt className={styles.question}>
              How do I download the Lilli app?
            </dt>
            <dd className={styles.answer}>
              Lilli is a web-based app, so to get an app icon on your phone you
              first need to visit the site in your phone's default browser.
              <br />
              <h3>For Safari on iPhone</h3>
              <ol>
                <li>Launch Safari on your ‌iPhone‌ or ‌iPad‌.</li>
                <li>
                  Navigate to the site that you want to create a Home screen
                  shortcut to.
                </li>
                <li>
                  Tap the Share icon (the square with an arrow pointing out of
                  it) at the bottom of the screen.
                </li>
                <li>
                  Scroll down to the list of actions and tap Add to Home Screen.
                  (If you don't see the action, scroll to the bottom and tap
                  Edit Actions, then tap Add next to the Add to Home Screen
                  action. After that, you'll be able to select it from the Share
                  Sheet.)
                </li>
              </ol>
              <h3>For Chrome on Android</h3>
              <ol>
                <li>Launch “Chrome” app.</li>
                <li>
                  Open the website or web page you want to pin to your home
                  screen.
                </li>
                <li>
                  Tap the menu icon (3 dots in upper right-hand corner) and tap
                  Add to homescreen.
                </li>
                <li>
                  You’ll be able to enter a name for the shortcut and then
                  Chrome will add it to your home screen.
                </li>
              </ol>
            </dd>

            <dt className={styles.question}>
              How do I receive alerts by email?
            </dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to your <a href="/profile">profile</a> and click the "Edit
                  Profile" button. Ensure you have set up a valid email address.
                </li>
                <li>
                  Go to the <a href="/reports">reports</a> section and toggle on
                  "Notification" at the top of the page
                </li>
              </ol>
            </dd>

            <dt className={styles.question}>How do I receive alerts by SMS?</dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to your <a href="/profile">profile</a> and click the "Edit
                  Profile" button. Ensure you have set up a valid SMS-capable
                  phone number.
                </li>
                <li>
                  Go to the <a href="/reports">reports</a> section and toggle on
                  "Notification" at the top of the page
                </li>
              </ol>
            </dd>

            <dt className={styles.question}>How do I respond to alerts?</dt>
            <dd className={styles.answer}></dd>

            <dt className={styles.question}>How do I reset my password?</dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to the <a href="/login">login</a> page.
                </li>
                <li>
                  At the bottom of the page, click the "Forgot Password" link.
                </li>
                <li>Enter the email address for your account.</li>
                <li>
                  You will receive an email instructing you how to reset your
                  password.
                </li>
              </ol>
            </dd>

            <dt className={styles.question}>
              How do I set up a new Lili user?
            </dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to the page for <a href="/lilli-users">your Lilli users</a>{" "}
                  and click the "New Service User" button.
                </li>
                <li>Fill in the relevant details for the service user.</li>
              </ol>
            </dd>

            <dt className={styles.question}>How do I set up a new licensee?</dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to the page for <a href="/staff">your staff</a> and click
                  the "New Staff Member" button.
                </li>
                <li>Fill in the relevant details for the new staff member.</li>
              </ol>
            </dd>

            <dt className={styles.question}>How do I change my profile?</dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to your <a href="/profile">profile</a> and click the "Edit
                  Profile" button.
                </li>
              </ol>
            </dd>
          </dl>
        </details>

        <details className={styles.details}>
          <summary>
            <h3>Set Up</h3>
          </summary>
          <dl>
            <dt className={styles.question}>
              When will I start to see data in Lilli
            </dt>
            <dd className={styles.answer}>
              Lilli hardware transmits sensor data as soon as it has been
              plugged in and establishes a connection to the server.
            </dd>

            <dt className={styles.question}>
              How will I know when an accident has occurred
            </dt>
            <dd className={styles.answer}>
              Lilli analyses streams of sensor data and detects when there has
              been a change that might indicate that a service user is behaving
              in an unexpected way. When this happens, alerts are sent out to
              everyone who is monitoring the service user.
            </dd>
          </dl>
        </details>
        <details className={styles.details}>
          <summary>
            <h3>Troubleshooting</h3>
          </summary>
          <dl>
            {/*          <dt className={styles.question}>
            What do I do if a data is not feeding into my dashboard
          </dt>
          <dd className={styles.answer}>
            [In-app diagnostics not designed yet, need instructions]
          </dd>
*/}
            <dt className={styles.question}>
              What happens if a sensor stops working
            </dt>
            <dd className={styles.answer}>
              If a single sensor stops working it can be a battery issue. Open
              the sensor and replace the battery. If this fails, contact Lilli
              for assistance.
            </dd>
            {/*
          <dt className={styles.question}>
            How can I tell if the data is accurate
          </dt>
          <dd className={styles.answer}>[Don't know how to answer this one]</dd>

          <dt className={styles.question}>
            What do I do if I think the data is inaccurate
          </dt>
          <dd className={styles.answer}>[Don't know how to answer this one]</dd>*/}

            <dt className={styles.question}>How do I email a report</dt>
            <dd className={styles.answer}>
              <ol>
                <li>
                  Go to the <a href="/profile">profile</a> page and ensure that
                  you have set a valid email address where you want to receive
                  reports.
                </li>
                <li>
                  Go to the <a href="/reports">Reports and Notifications</a>{" "}
                  page.
                </li>
                <li>
                  Toggle on the reports that you want to receive and they will
                  be sent to your inbox.
                </li>
              </ol>
            </dd>
            {/*
          <dt className={styles.question}>How do I download a report</dt>
          <dd className={styles.answer}>
            [TBD depending on our implementation]
          </dd>
*/}
            <dt className={styles.question}>
              How do I tag and comment on the data
            </dt>
            <dd className={styles.answer}>
              When looking at any service user on a day-by-day basis, there is a
              text box allowing you to add annotations to the day. This might be
              anything from "Went to visit a firend" to "There was a power cut
              on this day". Add any details that might be useful for other
              carers to know that happend on that day.
            </dd>

            <dt className={styles.question}>
              How do I edit and amend the reports?
            </dt>
            <dd className={styles.answer}>
              We currently do not support amending an existing report. You must
              delete an unwanted report and request a new one.
            </dd>

            <dt className={styles.question}>
              Can I import and export data from another system?
            </dt>
            <dd className={styles.answer}>
              Integrations with care software and patient records is done one a
              per-organisation basis. Contact Lilli to discuss requirements.
            </dd>
          </dl>
        </details>
        <details className={styles.details}>
          <summary>
            <h3>Installation</h3>
          </summary>
          <dl>
            <dt className={styles.question}>
              How do I install the tech in a Lilli users home?
            </dt>
            <dd className={styles.answer}>
              In order to install the tech, first make sure that you have
              permissions to install equipment, by going to your{" "}
              <a href="/profile">profile page</a>. Here it will say whether you
              have permissions to install equipment. If you do not have
              permissions, please contact your mannager to arrange this.
              <br />
              <h4>To install hardware for a new user:</h4>
              <ol>
                <li>
                  Go to the <a href="/lilli-users">Lilli users</a> section of
                  the app
                </li>
                <li>Click the "New service user" Button</li>
                <li>Add details for the service user, then click "Add user"</li>
                <li>
                  The user will now appear in the list of service users awaiting
                  hardware
                </li>
                <li>
                  Click the "Install" button for the relevant service user
                </li>
                <li>
                  Fill in the 16 digit device ID on the underside of the Lilli
                  hub being installed.
                </li>
              </ol>
              <h4>
                To install hardware for an already-configured recipient by
                looking up the service user's address:
              </h4>
              <ol>
                <li>
                  Go to the <a href="/install">Install</a> section of the app
                </li>
                <li>
                  Fill in the details of the location and click "Find address
                </li>
                <li>
                  If the Lilli user is awating installation, the address will be
                  found and you can insert the 16-digit serial number on the
                  bottom of the device
                </li>
              </ol>
            </dd>

            <dt className={styles.question}>
              How can I relocate a sensor in the home?
            </dt>
            <dd className={styles.answer}>
              Currenly sensors are assigned a location and must be placed in
              their designated room. The ability to move sensors will be coming
              to a future release of Lilli.
            </dd>

            <dt className={styles.question}>
              How can I relocate a sensor to another home?
            </dt>
            <dd className={styles.answer}>
              Sensors are paired to the Lilli gateway, and should not be moved
              apart from as an entire set.
            </dd>
          </dl>
        </details>
        <details className={styles.details}>
          <summary>
            <h3>Lilli users</h3>
          </summary>
          <dl>
            <dt className={styles.question}>
              How can I see which of the people within my care have the Lilli
              tech installed in their homes?
            </dt>
            <dd className={styles.answer}>
              Open the Lilli app and go to the{" "}
              <a href="/lilli-users">Lilli users</a> page. All users will be
              listed there.
            </dd>

            <dt className={styles.question}>
              How do I change the details of a Lilli user?
            </dt>
            <dd className={styles.answer}>
              Click into the page of an individual{" "}
              <a href="/lilli-users">Lilli user</a>, then click the "Edit
              profile" button towards the top right.
            </dd>
          </dl>
        </details>
        <details className={styles.details}>
          <summary>
            <h3>Privacy</h3>
          </summary>
          <dl>
            <dt className={styles.question}>What data is collected by Lilli</dt>
            <dd className={styles.answer}>
              We collect data that is required to run the service. For details
              about this please see our{" "}
              <SecondaryButton
                className={styles.privacyButton}
                onClick={() => navigate("/privacy")}
              >
                Privacy Policy
              </SecondaryButton>
            </dd>
          </dl>
        </details>
        <details className={styles.details}>
          <summary>
            <h3>Accessibility</h3>
          </summary>
          <dl>
            <dt className={styles.question}>Is the Lilli app accessible?</dt>
            <dd className={styles.answer}>
              We strive to offer a service that is accessible to all. Our goal
              is to be compliant with the WCAG 2.1 accessibility standards and
              we are actively working towards this. For more details please see
              our full &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://assets.intelligentlilli.com/accessibility/Lilli+Accessibility+Statement.pdf"
              >
                Accessibility Statement
              </a>
            </dd>
          </dl>
        </details>
      </div>
    </>
  );
};

const FAQs = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  if (!user) {
    return (
      <GuestPage
        title={"FAQs"}
        subtitle={
          <SecondaryButton
            startIcon={<NavigateBeforeIcon />}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </SecondaryButton>
        }
      >
        <FAQContent />
      </GuestPage>
    );
  } else {
    return (
      <Page className={styles.page}>
        <FAQContent />
      </Page>
    );
  }
};

export default FAQs;
