import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StaffList = Staff[];

export interface Staff {
  email: string;
  roles: string[];
  username: string;
  hubs: Hub[];
  surname: string;
  forenames: string;
  ts: string;
  id: string;
}

export interface Hub {
  properties?: Properties;
  billing: number;
  devicestring?: string;
  type: string;
  alert: number;
  ts: string;
  associationType: string;
  active: number;
  id: number;
  userstring: string;
  associationDate: string;
  organisationid: number;
}

export interface Properties {
  value: string;
  type: string;
  null: boolean;
}

const initialState: StaffList = [];

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaff(_, action) {
      return action.payload;
    },
    updateStaffMember<K extends keyof Staff>(
      state: StaffList,
      action: PayloadAction<{ index: number; key: K; value: Staff[K] }>
    ) {
      const { index, key, value } = action.payload;
      state[index][key] = value;
    },
  },
});

export const { setStaff, updateStaffMember } = staffSlice.actions;

export default staffSlice.reducer;
