import React from "react";
import theme from "../../theme.scss";
import { IconProps } from "./types";

export const ForwardIcon = ({ width, colour }: IconProps) => {
  return (
    <svg
      width={width || 24}
      height={width || 24}
      viewBox="0 0 24 24"
      fill="none"
      id="ForwardIcon"
    >
      <g>
        <path
          d="M21.2687 10.0112L18.0433 6.75264C17.719 6.42565 17.1916 6.42369 16.8646 6.74795C16.8631 6.74951 16.8615 6.75108 16.8599 6.75264C16.533 7.0769 16.531 7.6043 16.8552 7.93129C16.8568 7.93285 16.8584 7.93442 16.8599 7.93598L19.8271 10.9195L2.8333 10.9195C2.37309 10.9195 2 11.2926 2 11.7528C2 12.2131 2.37309 12.5861 2.8333 12.5861L19.8771 12.5861L16.8599 15.6033C16.5529 15.9252 16.5529 16.4315 16.8599 16.7534C17.1842 17.0804 17.7116 17.0824 18.0386 16.7581C18.0402 16.7566 18.0417 16.755 18.0433 16.7534L21.2687 13.5449C22.2438 12.5686 22.2438 10.9871 21.2687 10.0112Z"
          fill={colour || theme.neutral5}
        />
      </g>
    </svg>
  );
};
