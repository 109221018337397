import React from "react";
import styles from "./styles.module.scss";
import NewStyleModal from "../NewStyleModal";
import CircleCrossmarkIcon from "Styles/Icons/DesignSystem/CircleCrossMarkIcon";
import LilliButton from "Components/DesignSystemComponents/LilliButton/LilliButton";
import { NewDeleteIcon } from "Styles/Icons/DesignSystem/NewDeleteIcon";

interface ConfirmDeleteReportModalProps {
  setCloseConfirmModal: any;
  reportToDelete: any;
  deleteSelectedReport: any;
}

function ConfirmDeleteReportModal({
  setCloseConfirmModal,
  reportToDelete,
  deleteSelectedReport,
}: ConfirmDeleteReportModalProps) {
  return (
    <NewStyleModal
      showCloseIcon={false}
      hide={() => setCloseConfirmModal(true)}
      contextClass={styles.contextClass}
      cypresstestid="Delete-Report-Btn-Submit"
    >
      <div className={styles.delete}>
        <CircleCrossmarkIcon width={40} />
        <h1>
          Delete {reportToDelete?.typeid} report for{" "}
          {reportToDelete?.userstring}?
        </h1>
        <p>
          You are about to delete this report, this cannot be undone. Are you
          sure you want to delete it?
        </p>
        <div className={styles.button_group}>
          <LilliButton
            variant="secondary"
            onClick={() => setCloseConfirmModal(true)}
          >
            Cancel
          </LilliButton>
          <LilliButton
            leftIcon={<NewDeleteIcon />}
            variant="danger"
            onClick={() => deleteSelectedReport(reportToDelete)}
          >
            Delete
          </LilliButton>
        </div>
      </div>
    </NewStyleModal>
  );
}

export default ConfirmDeleteReportModal;
