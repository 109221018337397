import React from "react";
import styles from "./styles.module.scss";
// Forms
import { Form, Field } from "react-final-form";
import FormError from "../../Components/FormError";

const DeviceIdForm = (props) => {
  // prevent unwanted special characters being entered and convert lower case letters to upper case
  const capitalize = (value) =>
    value ? value.replace(/[^A-Z0-9]/gi, "").toUpperCase() : "";

  return (
    <Form
      onSubmit={(values) => props.onSubmit(values.id)}
      validate={(values) => {
        const errors = {};
        if (!values.id) {
          errors.id = "Required";
        }
        if (values.id?.length !== 16) {
          errors.id = "Device ID should be 16 digits long";
        }
        // a regex that checks the 8th and 9th digits to see if they are 13 or 14.
        const regex = /^.{7}(13|14).*/;
        if (!regex.test(values.id)) {
          errors.id =
            "It looks like you've entered the Serial Number instead. Please enter the 16-digit code found above the QR code on the back of the box.";
        }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <form
          className={styles.form}
          id="qr-code-install"
          // the submit button is further up the DOM tree in InstallLinkDevice using document.getElementById https://final-form.org/docs/react-final-form/faq
          onSubmit={handleSubmit}
        >
          <Field name="id" parse={capitalize}>
            {({ input, meta }) => (
              <div className={styles.input}>
                <p>
                  If you cannot scan your QR code enter the 16 digit number
                  above it here:
                </p>
                <label>
                  Enter the 16 digit code:
                  <input {...input} type="text" />
                </label>

                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          {props.error && <FormError error={props.error} />}
        </form>
      )}
    />
  );
};

export default DeviceIdForm;
