import React, { useRef, useCallback, useState } from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryLabel,
} from "victory";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import * as htmlToImage from "html-to-image";
import { NewDownloadIcon } from "../../../Styles/Icons/DesignSystem";
import { format } from "date-fns";

const CareActChart = ({
  isPdf,
  chartData,
  startDates,
  questionText,
  formattedDateRange,
}) => {
  const [onHover, setOnHover] = useState(false);
  // create a ref for the chart element
  const ref = useRef(null);

  // First get rid of any undefined or null values in the data array
  const cleanData = chartData?.data?.filter(
    (item) => item !== undefined && item !== null
  );

  // First check whether the data array in chartData has string values
  const isTimeData = cleanData?.every((item) => typeof item === "string");

  const processedData = cleanData
    ?.map((item, index) => {
      // check if the item is a string
      if (isTimeData) {
        // The item is therefore a string representation of time in the format"HH:mm"
        // Convert the string to a number representing minutes since midnight
        const time = item.split(":");
        const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
        // Add an hour to the minutes to shift the chart up off the x-axis this is adjusted for in the y-axis labels below
        const adjustedMinutes = minutes + 60;
        return {
          x: startDates[index],
          y: adjustedMinutes,
        };
      }

      return {
        x: startDates[index],
        y: item,
      };
    })
    .filter((item) => !!item.x);
  const maxYValue = Math.max(...(processedData?.map((item) => item.y) || []));
  const minYValue = Math.min(...(processedData?.map((item) => item.y) || []));
  let maxYDomain;
  // if maxYValue is 0, set maxYDomain to 1
  if (maxYValue === 0) {
    maxYDomain = 1;
  }
  // if maxYValue is less than 50 set maxYDomain to the next multiple of 1
  if (maxYValue < 10) {
    maxYDomain = Math.ceil(maxYValue / 1) + 1;
  }
  // if maxYValue is less than 50 set maxYDomain to the next multiple of 5
  else if (maxYValue < 50) {
    maxYDomain = (Math.ceil(maxYValue / 5) + 1) * 5;
  }
  // if maxYValue is less than 100 set maxYDomain to the next multiple of 20
  else if (maxYValue < 100) {
    maxYDomain = (Math.ceil(maxYValue / 20) + 1) * 20;
  }
  // if maxYValue is less than 200 set maxYDomain to the next multiple of 50
  else if (maxYValue < 500) {
    maxYDomain = (Math.ceil(maxYValue / 50) + 1) * 50;
  }
  // if maxYValue is less than 500 set maxYDomain to the next multiple of 100
  else {
    maxYDomain = (Math.ceil(maxYValue / 100) + 1) * 100;
  }

  const downloadScreenshot = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    htmlToImage
      .toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${questionText}${formattedDateRange}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, questionText, formattedDateRange]);

  return (
    <div className={styles.chart} ref={ref}>
      <VictoryChart
        height={280}
        width={440}
        maxDomain={{ y: maxYDomain }}
        minDomain={{ y: isTimeData ? minYValue - 60 : -0.1 * maxYValue }}
        padding={{ top: 40, bottom: 50, left: 40, right: 40 }}
      >
        <VictoryLabel text={chartData.y_axis_title} x={150} y={20} />
        <VictoryAxis
          crossAxis={false}
          style={{
            grid: {
              stroke: theme.neutral3,
              strokeWidth: 1,
            },
            tickLabels: { fill: theme.neutral4, fontSize: 8 },
            axis: { stroke: theme.neutral3, strokeWidth: 1 },
          }}
          tickFormat={(t) => {
            if (
              t === startDates[0] ||
              t === startDates[startDates.length - 2]
            ) {
              return format(new Date(t), "dd MMM");
            }
          }}
        />
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          style={{
            grid: {
              stroke: theme.neutral3,
              strokeWidth: 1,
            },
            tickLabels: { fill: theme.neutral4, fontSize: 8 },
            axis: { stroke: theme.neutral3, strokeWidth: 1 },
          }}
          tickFormat={(t) => {
            if (isTimeData) {
              let hours = Math.floor(t / 60);
              // Revert the hour added earlier in this component by displaying the correct axis lable.
              hours = hours - 1;
              if (hours > 23) {
                hours = hours - 24;
              }
              if (hours < 0) {
                hours = hours + 24;
              }
              const minutes = t % 60;
              return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}`;
            }
            // if the tick is not an integer return an empty string
            if (t % 1 !== 0) {
              return "";
            }
            return t;
          }}
        />
        {processedData && (
          <VictoryLine
            interpolation="monotoneX"
            data={processedData}
            style={{ data: { stroke: theme.primary3 } }}
          />
        )}
        {processedData && (
          <VictoryScatter
            data={processedData}
            size={3}
            style={{ data: { fill: theme.primary6 } }}
          />
        )}
      </VictoryChart>

      <button
        onClick={downloadScreenshot}
        className={styles.button}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <NewDownloadIcon
          colour={
            isPdf ? theme.white : onHover ? theme.neutral4 : theme.neutral6
          }
        />
      </button>
    </div>
  );
};

export default CareActChart;
