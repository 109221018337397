import React from "react";
import styles from "./styles.module.scss";
import AltSpinner from "../Spinners/AltSpinner";
import { SuccessTickIcon } from "../../Styles/Icons/DesignSystem";

const SensorSignal = ({ online, loading }) => {
  if (loading) {
    return <AltSpinner small />;
  }
  if (online) {
    return (
      <div className={styles.connected}>
        <SuccessTickIcon />{" "}
        <div className={styles.connected_text}>Connected</div>
      </div>
    );
  }
};

export default SensorSignal;
