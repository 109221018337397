import { postHubPairingMode } from "@intelligentlilli/api-layer";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type OpenHubPairingProps = { 
  hubId: string | undefined; 
  installationComplete: boolean; 
  frequencyOfRepairing?: number; 
}

export const useOpenHubPairing = ({
  hubId,
  installationComplete,
  frequencyOfRepairing = 150000, // 2.5 minutes
}: OpenHubPairingProps) => {
  const server = useSelector((state) => state.session.server);
  useEffect(() => {
    const postHubPairing = async () => {
      if (!installationComplete) {
        try {
          await postHubPairingMode(server, hubId, "web");
        } catch (error) {
          console.error("Error in postHubPairingMode", error);
        }
      }
    };
    postHubPairing();

    const interval = setInterval(async () => {
      if (!installationComplete) {
        try {
          await postHubPairingMode(server, hubId, "web");
        } catch (error) {
          console.error("Inside interval - Error in postHubPairingMode", error);
        }
      }
    }, frequencyOfRepairing); // re-open pairing mode every X seconds
    return () => clearInterval(interval);
  }, [installationComplete, frequencyOfRepairing, server, hubId]);
};
