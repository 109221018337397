import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface ButtonSpinnerProps {
  white?: boolean;
}

const ButtonSpinner = ({ white }: ButtonSpinnerProps) => {
  return (
    <div
      className={classNames(styles.lds_spinner, {
        [styles.white]: white,
      })}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonSpinner;
