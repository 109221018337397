import React, { CSSProperties } from "react";
import styles from "./styles.module.scss";

interface CareIdProps {
  careID?: string;
  style?: CSSProperties;
}

const CareId = ({ careID, style }: CareIdProps) => (
  <p className={styles.id} style={style}>
    Care Record ID: {careID ? careID : "Not set"}
  </p>
);

export default CareId;
