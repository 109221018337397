import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface CardProps {
  children: React.ReactNode;
  style?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Card = ({ style, children, onClick }: CardProps) => (
  <div className={classNames(styles.card, style)} onClick={onClick}>
    {children}
  </div>
);

export default Card;
