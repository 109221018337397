import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { CloseIcon } from "../../../Styles/Icons/DesignSystem";
import PrimaryButton from "../../PrimaryButton";

const ConfirmExitInstall = ({ setShowAnimation, setExitInstall }) => {
  const EXIT_INSTALL_WARNING =
    "You are about to exit this installation. Your progress will not be saved. If you’re having any issues, please contact support at +44 (0)758 800 8527 or email support@intelligentlilli.com.";

  return (
    <div className={styles.exit_install_container}>
      <div className={styles.warning_icon}>
        <div className={styles.cancel_icon_circle}>
          <CloseIcon width={33} />
        </div>
      </div>
      <div className={styles.title}>Exit install?</div>
      <div className={styles.warning}>{EXIT_INSTALL_WARNING}</div>
      <div className={styles.buttons}>
        <PrimaryButton
          onClick={() => setShowAnimation(false)}
          style={{
            width: "100%",
            marginRight: "12px",
            backgroundColor: "white",
            borderRadius: "8px",
            border: `1px solid ${theme.primary3}`,
            lineHeight: 1.75,
            padding: "6px 8px",
          }}
        >
          Cancel
        </PrimaryButton>
        <PrimaryButton
          onClick={setExitInstall}
          style={{
            width: "100%",
            backgroundColor: theme.risk3,
            borderRadius: "8px",
            border: `1px solid ${theme.risk3}`,
            lineHeight: 1.75,
            padding: "6px 8px",
            color: theme.neutral7,
          }}
        >
          Exit install
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ConfirmExitInstall;
