import { NewDownloadIcon } from "Styles/Icons/DesignSystem";
import { CircleCheckIcon } from "Styles/Icons/DesignSystem/CircleCheckIcon";
import CircleCrossmarkIcon from "Styles/Icons/DesignSystem/CircleCrossMarkIcon";
import React, { useEffect, useState } from "react";
import theme from "Styles/theme.scss";
import { generateAndDownloadReport } from "Services/Utils/generateAndDownloadReport";
import { useAppSelector } from "State/hooks";
import { useIsMounted } from "./useIsMounted";
import NewSpinnerIcon from "Styles/Icons/DesignSystem/NewSpinnerIcon";

export const useDownloadReport = ({
  report,
  isSmallScreen,
  isMediumScreen,
}: {
  report: any;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
}) => {
  const server = useAppSelector((state) => state.session.server);
  const [downloadingReport, setDownloadingReport] = useState<
    boolean | undefined
  >(undefined);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [error, setError] = useState(false);

  const isMounted = useIsMounted();

  const getButtonText = () => {
    if (isSmallScreen) return null; // Always hide on small screens

    if (downloadingReport && isMediumScreen) return "Downloading";
    if (downloadingReport) return "Downloading...";
    if (downloaded) return "Downloaded";
    if (isMediumScreen && error) return "Failed";
    if (error) return "Download failed";

    if (isMediumScreen) return "Download";

    return "Download PDF"; // Large screen default
  };

  const getButtonIcon = () => {
    if (downloadingReport) return <NewSpinnerIcon />;
    if (downloaded) return <CircleCheckIcon />;
    if (error) return <CircleCrossmarkIcon colour={theme.risk6} />;
    return <NewDownloadIcon />;
  };

  const downloadReport = async () => {
    await generateAndDownloadReport({
      report,
      server,
      downloadingReport,
      setDownloadingReport,
      setDownloaded,
      error,
      setError,
      isMounted, // Ensure proper cleanup
    });
  };

  useEffect(() => {
    if (downloaded) {
      setTimeout(() => setDownloaded(false), 5000);
    }
  }, [downloaded]);

  // Reset "error" state after 5s
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 5000);
    }
  }, [error]);

  return {
    downloadReport,
    error,
    getButtonText,
    getButtonIcon,
  };
};
