import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import theme from "Styles/theme.scss";
import { IconProps } from "./types";
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";

export const NewDownloadIcon = ({ width, colour }: IconProps) => {
  return (
    <FontAwesomeIcon
      icon={faCircleDown}
      color={colour ?? theme.neutral7}
      fontSize={width ?? "16px"}
    />
  );
};
