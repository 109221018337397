import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../Components/Page";
import GuestPage from "../../Components/GuestPage";
import SecondaryButton from "../../Components/SecondaryButton";
import { useAppSelector } from "State/hooks";

const Error404 = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  if (user) {
    return (
      <Page>
        <h1>Page not found</h1>
        <SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
      </Page>
    );
  } else {
    return (
      <GuestPage>
        <h1>Page not found</h1>
        <SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
      </GuestPage>
    );
  }
};

export default Error404;
