import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PropTypes } from "prop-types";
// State
import { useAppSelector } from "State/hooks";

const AuthenticatedRoute = ({ qr, title, initialURL, children }) => {
  const user = useAppSelector((state) => state.user);

  // Set the title of the browser tab
  useEffect(() => {
    document.title = `Lilli - ${title}`;
  });

  if (qr) {
    return <Navigate to={"/qr"} />;
  }
  if (!user) {
    return <Navigate to={"/login"} state={{ initialURL: initialURL }} />;
  }
  return children;
};

AuthenticatedRoute.propTypes = {
  qr: PropTypes.string,
  title: PropTypes.string,
  initialURL: PropTypes.string,
  children: PropTypes.element,
};

export default AuthenticatedRoute;
