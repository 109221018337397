import { Roles } from "@intelligentlilli/api-layer";

const AUTH_ADMIN = "lilli.auth/admin";
const ADMIN = "admin";
const AUTH_MANAGER = "lilli.auth/manager";
const MANAGER = "manager";
const AUTH_STAFF = "lilli.auth/user";
const STAFF = "user";
const AUTH_INSTALLER = "lilli.auth/installer";
const INSTALLER = "installer";
const AUTH_FF = "lilli.auth/friend-or-family";
const FF = "friend-or-family";

const hasAdminRole = (rolesArray: Roles[]) =>
  rolesArray?.includes(ADMIN) || rolesArray?.includes(AUTH_ADMIN);
const hasManagerRole = (rolesArray: Roles[]) =>
  rolesArray?.includes(MANAGER) || rolesArray?.includes(AUTH_MANAGER);
const hasStaffRole = (rolesArray: Roles[]) =>
  rolesArray?.includes(STAFF) || rolesArray?.includes(AUTH_STAFF);
const hasInstallerRole = (rolesArray: Roles[]) =>
  rolesArray?.includes(INSTALLER) || rolesArray?.includes(AUTH_INSTALLER);
const hasFriendOrFamilyRole = (rolesArray: Roles[]) => rolesArray?.includes(FF) || rolesArray?.includes(AUTH_FF);

export const getIsUserJustAnInstaller = (rolesArray: Roles[]) => {
  return (
    hasInstallerRole(rolesArray) &&
    !hasAdminRole(rolesArray) &&
    !hasManagerRole(rolesArray) &&
    !hasStaffRole(rolesArray) && 
    !hasFriendOrFamilyRole(rolesArray)
  );
};

export const getUserHasInstallerRole = (rolesArray: Roles[]) => {
  return hasInstallerRole(rolesArray);
};

export const getIsUserStaff = (rolesArray: Roles[]) => {
  return (
    hasStaffRole(rolesArray) &&
    !hasAdminRole(rolesArray) &&
    !hasManagerRole(rolesArray)
  );
};

export const getUserIsManagerOrAdmin = (rolesArray: Roles[]) => {
  return hasManagerRole(rolesArray) || hasAdminRole(rolesArray);
};

export const getUserRoleAsString = (rolesArray: Roles[]) => {
  const result = hasAdminRole(rolesArray)
    ? "Admin"
    : hasManagerRole(rolesArray)
      ? "Manager"
      : hasStaffRole(rolesArray)
        ? "Staff"
        : hasInstallerRole(rolesArray)
          ? "Installer"
          : hasFriendOrFamilyRole(rolesArray)
            ? "Friend or Family"
          : "";
  return result;
};
