import { parseISO, getHours } from "date-fns";
import { getDoorOpenedAtNightFirstLine, getNoTimeOutsideFirstLine } from "./";

export const handleIndependence = (data, filterName) => {
  const noTimeOutside = data?.map((day) => {
    const timeOutside = day?.activityData
      ?.filter((activity) => activity.userState === "outside")
      .reduce((total, current) => total + current.durationMinutes, 0);
    return {
      date: day?.date,
      value: timeOutside,
      firstLineText: getNoTimeOutsideFirstLine(timeOutside),
      isHighlighted: timeOutside === 0,
      noData: getNoTimeOutsideFirstLine(timeOutside) === "No data",
    };
  });
  const doorOpenedAtNight = data?.map((day) => {
    const numberOfDoorEventsAtNight = day?.openedAt?.reduce(
      (count, timestamp) => {
        const date = parseISO(timestamp);
        return getHours(date) < 6 ? count + 1 : count;
      },
      0
    );
    return {
      date: day?.date,
      value: day?.value,
      firstLineText: getDoorOpenedAtNightFirstLine(numberOfDoorEventsAtNight),
      isHighlighted: numberOfDoorEventsAtNight > 0,
      noData:
        getDoorOpenedAtNightFirstLine(numberOfDoorEventsAtNight) === "No data",
    };
  });

  if (filterName === "doorOpenedAtNight") {
    return doorOpenedAtNight;
  }
  return noTimeOutside;
};
