import React from "react";
import theme from "../../theme.scss";

interface EditProfileIconProps {
  width?: string | number;
  height?: string | number;
  colour?: string;
}

export const EditProfileIcon = ({
  width,
  height,
  colour,
}: EditProfileIconProps) => {
  return (
    <svg
      width={width || 17}
      height={height || 18}
      viewBox="0 0 17 18"
      id="EditIcon"
      fill={theme.white}
    >
      <path
        d="M7.66667 2.75H2.48148C2.08857 2.75 1.71175 2.90608 1.43392 3.18392C1.15608 3.46175 1 3.83857 1 4.23148V14.6019C1 14.9948 1.15608 15.3716 1.43392 15.6494C1.71175 15.9272 2.08857 16.0833 2.48148 16.0833H12.8519C13.2448 16.0833 13.6216 15.9272 13.8994 15.6494C14.1772 15.3716 14.3333 14.9948 14.3333 14.6019V9.41667"
        stroke={colour || "#4B6360"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4356 2.35796C13.7173 2.07624 14.0994 1.91797 14.4978 1.91797C14.8962 1.91797 15.2783 2.07624 15.56 2.35796C15.8417 2.63968 16 3.02177 16 3.42018C16 3.81859 15.8417 4.20068 15.56 4.4824L8.8326 11.2098L6 11.918L6.70815 9.08537L13.4356 2.35796Z"
        stroke={colour || "#4B6360"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
