import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import TroubleshootingHeader from "../TroubleshootingHeader";
import TroubleshootingDesktopBody from "../TroubleshootingDesktopBody";
import TroubleshootingMobileBody from "../TroubleshootingMobileBody";
import TroubleshootingMethods from "../TroubleshootingMethods";
import InstallationGoBackButton from "../../InstallationGoBackButton";

const Troubleshooting = ({
  isDesktop,
  setShowAnimation,
  isVisisble,
  sensorName,
  helpChoice,
  setHelpChoice,
  troubleshootingOptions,
  troubleshootingHeaders,
  troubleshootingInstructions,
  troubleshootingGenericHeader,
  isInstall = true,
}) => {
  const sensorType = sensorName?.includes("Power")
    ? "Smart plug"
    : sensorName?.includes("Motion")
      ? "Motion sensor"
      : "Door sensor";

  const troubleshootingTips =
    helpChoice === "Factory reset the sensor" && sensorName
      ? troubleshootingInstructions?.[helpChoice]?.[sensorType]
      : troubleshootingInstructions?.[helpChoice];

  const headerToDisplay = helpChoice
    ? troubleshootingHeaders[helpChoice]
    : troubleshootingGenericHeader;

  /* Scroll to the top of the sensor guide when opened */
  // Create a ref to the top of the sensor guide container - only for mobile
  const containerRef = useRef(null);
  useEffect(() => {
    if (isVisisble && containerRef.current && !isDesktop) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isVisisble, isDesktop, helpChoice]);

  return (
    <div className={styles.sensor_guide_container}>
      {isDesktop ? (
        <div className={styles.desktop}>
          <div className={styles.desktop_top}>
            <div className={styles.desktop_left}>
              <TroubleshootingHeader
                isDesktop={isDesktop}
                instructions={headerToDisplay}
                sensorType={sensorType}
              />
            </div>
            <div className={styles.desktop_right}>
              {helpChoice ? (
                <TroubleshootingDesktopBody
                  instructions={troubleshootingTips}
                  sensorType={sensorType}
                  helpChoice={helpChoice}
                />
              ) : (
                <TroubleshootingMethods
                  options={troubleshootingOptions}
                  helpChoice={helpChoice}
                  setHelpChoice={setHelpChoice}
                  closeModal={() => {
                    setHelpChoice(null);
                    setShowAnimation(false);
                  }}
                  isDesktop={isDesktop}
                  sensorType={sensorType}
                />
              )}
            </div>
          </div>
          <div className={styles.desktop_bottom_buttons}>
            {helpChoice && (
              <InstallationGoBackButton
                label="Go back to troubleshooting options"
                onClick={() => {
                  setHelpChoice(null);
                }}
                marginTop="16px"
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.mobile} ref={containerRef}>
          <TroubleshootingHeader
            isDesktop={isDesktop}
            instructions={headerToDisplay}
            sensorType={sensorType}
          />
          <div className={styles.mobile_body}>
            {!helpChoice ? (
              <TroubleshootingMethods
                options={troubleshootingOptions}
                helpChoice={helpChoice}
                setHelpChoice={setHelpChoice}
                closeModal={() => {
                  setHelpChoice(null);
                  setShowAnimation(false);
                }}
                sensorType={sensorType}
              />
            ) : (
              <TroubleshootingMobileBody
                ref={containerRef}
                helpChoice={helpChoice}
                setShowAnimation={setShowAnimation}
                setHelpChoice={setHelpChoice}
                instructions={troubleshootingTips}
                sensorType={sensorType}
                isInstall={isInstall}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Troubleshooting;
