import React from "react";
import Select from "react-select";
import theme from "../../../Styles/theme.scss";

const LilliSelect = ({
  options,
  value,
  onChange,
  isSearchable = false,
  dataPrivate = true,
  name,
  placeholder,
  isClearable = false,
  menuPlacement = "bottom",
  withinModal,
}) => {
  return (
    <Select
      data-private={dataPrivate}
      placeholder={placeholder}
      options={options}
      name={name}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      components={{ IndicatorSeparator: () => null }}
      styles={{
        control: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          borderColor: isFocused ? theme.primary3 : theme.neutral3,
          marginTop: 4,
          fontSize: theme.bodyMedium,
          color: theme.neutral7,
          borderRadius: 8,
          ":hover": { borderColor: theme.primary3 },
          boxShadow: "none",
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          fontSize: "16px",
          backgroundColor: isFocused ? theme.neutral2 : null,
          color: theme.neutral7,
          ":active": { backgroundColor: theme.neutral3 },
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: withinModal ? 550 : "auto",
        }),
      }}
    />
  );
};

export default LilliSelect;
