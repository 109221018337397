import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";

import { useNavigate } from "react-router-dom";

import NewStyleModal from "../NewStyleModal";
import PrimaryButton from "../PrimaryButton";

interface DashboardJustReleasedProps {
  releaseTitle: string;
  releaseSubheading: string;
  videoSourceURL?: string;
  showVideoButton?: boolean;
  videoDescription?: string;
  showButton?: boolean;
  pageToNavigateTo?: string;
}

const DashboardJustReleased = ({
  releaseTitle,
  releaseSubheading,
  videoSourceURL,
  showVideoButton,
  videoDescription,
  showButton,
  pageToNavigateTo,
}: DashboardJustReleasedProps) => {
  const [showJustReleasedModal, setShowJustReleasedModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.just_released_container}>
        <div className={styles.just_released_icon_container}>
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none">
            <path
              d="M12.1111 1L1 14.2H11L9.88889 23L21 9.8H11L12.1111 1Z"
              stroke={theme.neutral7}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className={styles.title_and_subheading_container}>
          <div className={styles.just_released_title}>{releaseTitle}</div>
          <div className={styles.just_released_subheading}>
            {releaseSubheading}
          </div>
        </div>

        {showVideoButton ? (
          <div
            className={styles.just_released_clickable_text}
            onClick={() => {
              setShowJustReleasedModal(true);
            }}
          >
            Watch the video
          </div>
        ) : null}
      </div>
      {showJustReleasedModal && (
        <NewStyleModal
          showCloseIcon={true}
          hide={() => setShowJustReleasedModal(false)}
          title={releaseTitle}
        >
          <div className={styles.iframe_container}>
            <iframe
              width="100%"
              height="100%"
              src={videoSourceURL}
              loading="lazy"
              title="Just Released Video Player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
          <div className={styles.description_and_button_container}>
            <div className={styles.release_description_text}>
              {videoDescription}
            </div>
            {showButton && pageToNavigateTo ? (
              <PrimaryButton onClick={() => navigate(pageToNavigateTo)}>
                Try it now
              </PrimaryButton>
            ) : null}
          </div>
        </NewStyleModal>
      )}
    </>
  );
};

export default DashboardJustReleased;
