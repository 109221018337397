import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
// DateTime
import {
  addDays,
  addMonths,
  endOfDay,
  format,
  subDays,
  subMonths,
  startOfDay,
} from "date-fns";
// UTILS
import {
  getRiskDates,
  getServiceUserPageURL,
  getFromDate,
  getUrlParameter,
} from "@intelligentlilli/lilli-utils";
import {
  getBathroomEventLogDataFromBathroomInference,
  getEventLogData,
  getV1EventLogBathroomTimestamps,
  mergeMovementV3DayAndNightEventLog,
} from "../../Services/Utils/EventLog";
// Hooks
import {
  usePrepareMovementV3Data,
  usePrepareIndependenceData,
  usePrepareSustenanceData,
  usePrepareTimeOutsideData,
  useCombineIndependenceAndTimeOutside,
  usePrepareTemperatureData,
  usePrepareBathroomData,
  usePrepareBedroomData,
  useHubDailyNotes,
  useFetchEventLog,
  useGetUserRole,
  useFetchBehaviours,
  useFade,
  useReportsHook,
} from "../../Services/Hooks";
// State
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../State/hooks";
// Constants
import { NAVIGATION_TABS } from "./constants";
// Components
import Page from "../../Components/Page";
import NavigationTabs from "../../Components/NavigationTabs";
import NavTabBody from "../../Components/NavTabBody";
import NewStyleModal from "../../Components/NewStyleModal";
import DateSelector from "../../Components/DateSelector";
import DevicesOverview from "../../Components/DevicesOverview";
import ServiceUserInfo from "../../Components/ServiceUserInfo";
import EditServiceUserProfile from "../../Components/EditServiceUserProfile";
import PrimaryButton from "../../Components/PrimaryButton";
import NoDataBox from "../../Components/NoDataBox";
import EventLog from "../../Charts/EventLog";
import EditNotes from "../../Components/EditNotes";
import ServiceUserNotificationSettings from "../../Components/ServiceUserNotificationSettings";
import CreateNewReportModal from "../../Components/CreateNewReportModal";
// Behaviour Components
import BehaviourOverview from "../../Components/BehaviourOverview";
import BehaviourSustenance from "../../Components/BehaviourSustenance";
import BehaviourTemperature from "../../Components/BehaviourTemperature";
import BehaviourIndependence from "../../Components/BehaviourIndependence";
import BehaviourBathroomNew from "../../Components/BehaviourBathroomNew";
import BehaviourDayTime from "../../Components/BehaviourDayTime";
import BehaviourNightTime from "../../Components/BehaviourNightTime";
import { useGetHardwareStatus } from "../../Services/Hooks/useGetHardwareStatus";
import { useChangeLilliUserPage } from "../../Services/Hooks/useChangeLilliUserPage";
import RemoveServiceUserModal from "../../Components/RemoveServiceUserModal";

function LilliUser() {
  const id = useParams().id || 0; // Get the hub id from the url parameter
  const navigate = useNavigate();

  /* 
  First we extract all the parameters from the url that determine what the user should see 
  */
  // Extract the "day/week/month" dateType
  const urlDateType = getUrlParameter("view") || "day";

  const handleTodayButtonClick = () => {
    changePage(urlTab, new Date(), new Date(), "day");
  };
  // Extract the end date (a string like "2021-08-17")
  const urlEndDateString =
    getUrlParameter("end") || format(new Date(), "yyyy-MM-dd");
  const urlEndDate = endOfDay(new Date(urlEndDateString)); // returns a Date like this: Fri May 27 2022 23:59:59 GMT+0100 (British Summer Time)

  // Extract the start date (a string like "2021-08-17")
  const urlStartDateString =
    getUrlParameter("start") ||
    format(getFromDate(urlEndDate, urlDateType), "yyyy-MM-dd");
  const urlStartDate = startOfDay(new Date(urlStartDateString));

  // Get the previous start & end dates depending on the dateType selected
  const prevEndDate = urlStartDate && subDays(urlStartDate, 1);
  const prevStartDate =
    urlStartDate && urlDateType === "day"
      ? subDays(urlEndDate, 1)
      : urlDateType === "week"
        ? subDays(urlStartDate, 7)
        : subMonths(urlStartDate, 1);

  // Create previous dates as a string; this is to stop infinite loops when used inside an useEffect dependency array
  // At the moment, this is being used within the usePrepareMovementData hook
  const prevStartDateString = format(prevStartDate, "yyyy-MM-dd");
  const prevEndDateString = format(prevEndDate, "yyyy-MM-dd");

  // Extract the tabs that should be shown into an object. Note the defaults where they're not specified in the url.
  const urlTab = {
    tab: getUrlParameter("tab") || "overview",
  };

  // redux state
  const dispatch = useDispatch();
  const server = useAppSelector((state) => state.session.server);
  const user = useAppSelector((state) => state.user);
  // does this user have access to the notifications page
  const isManagerOrAdmin =
    user?.roles?.includes("manager") || user?.roles?.includes("admin");
  const orgsWithNotifications = [38, 46]; // if you're testing with dev URL, add orgID 1
  const canSeeNotifications =
    orgsWithNotifications.includes(user.organisationId) && isManagerOrAdmin;
  const TABS_WITH_NOTIFICATIONS = NAVIGATION_TABS.concat("notifications");
  // Extract the data we have on the service user requested
  const serviceUsers = useAppSelector(
    (state) => state.serviceUsers,
    (prev, next) => {
      return prev.length === next.length;
    }
  );

  const serviceUserIds = serviceUsers
    ?.filter(
      (su) => su?.serviceUserProperties?.installationStatus === "complete"
    )
    ?.map((su) => su.id);

  const { goToNext, goToPrevious } = useChangeLilliUserPage({
    serviceUserIds,
    currentServiceUser: id,
  });

  const indexOfSU = serviceUsers.findIndex(
    (serviceUser) => serviceUser.id.toString() === id
  );
  const serviceUser = useAppSelector((state) =>
    state.serviceUsers.find((su) => su.id.toString() === id)
  );

  // Fetch the data for the service user
  void useFetchBehaviours({
    endDateString: urlEndDate.toISOString(),
    startDateString: urlStartDate.toISOString(),
    dispatch: dispatch,
    serviceUserId: parseInt(id),
    behaviour: urlTab.tab,
    server: server,
  });
  void useFetchEventLog(id, urlEndDateString);

  /*--- other useful variables --- */
  // When refreshing the app on the lilli user page we need to wait until the initial data fetch has set up the state store
  // before we attempt to save the behaviour data to it.
  // const stateReadyToAcceptData = !!serviceUserData;
  // const stateReadyToAcceptDataRef = useRef(); // A ref to control behaviour data fetching
  const noDeviceInstalledText = `${
    serviceUser?.userstring || "This person"
  } does not have or no longer has a Lilli hub`;
  const hasDevice =
    serviceUser?.serviceUserProperties?.installationStatus === "complete";
  // Get the time of the first event for the service user in question
  const firstEventTimestamp = serviceUser?.firstEventTimestamp;
  // If we have a first event timestamp property set it as the earliest date. Otherwise use the 1st Jan 2020
  const earliestDate = firstEventTimestamp
    ? endOfDay(new Date(firstEventTimestamp))
    : endOfDay(new Date("2020-01-01T10:03:20.608Z"));
  /*-----End------*/

  /*--- Pull behaviour data out of Redux --- */
  const reduxEventLogData = useAppSelector(
    (state) => state.serviceUsersData[id]?.events?.[urlEndDateString],
    isEqual
  );
  const reduxTempDailyResultsData = useAppSelector(
    (state) => state.serviceUsersData[id]?.temperatureAnalysis?.dailyResults,
    isEqual
  );
  const reduxSustenanceData = useAppSelector(
    (state) => state.serviceUsersData[id]?.sustenanceAnalysis,
    isEqual
  );
  const reduxBedroomData = useAppSelector(
    (state) => state.serviceUsersData[id]?.bedroomAnalysis,
    isEqual
  );
  const reduxIndepData = useAppSelector(
    (state) => state.serviceUsersData[id]?.independenceAnalysis,
    isEqual
  );
  const reduxTimeOutsideData = useAppSelector(
    (state) => state.serviceUsersData[id]?.timeOutsideV2,
    isEqual
  );
  const reduxMainDoorData = useAppSelector(
    (state) => state.serviceUsersData[id]?.mainDoorActivity,
    isEqual
  );
  const reduxMoveV3Data = useAppSelector(
    (state) => state.serviceUsersData[id]?.movementAnalysisV3,
    isEqual
  );
  const reduxBathInferenceData = useAppSelector(
    (state) => state.serviceUsersData[id]?.bathroomInference,
    isEqual
  );
  /*-----End------*/

  // Is it an installer-only user? - restrict what they can see
  const { userIsJustAnInstaller, userHasInstallerRole } = useGetUserRole();

  // local state
  const [modalSelected, setModalSelected] = useState(""); // Bool for displaying the edit profile view
  const [noteToDelete, setNoteToDelete] = useState();

  // Function to allow navigation on the lilli user page
  const changePage = (
    activeTabs = urlTab,
    endDate = urlEndDate,
    startDate = urlStartDate,
    dateType = urlDateType
  ) => {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    navigate(
      getServiceUserPageURL(
        activeTabs,
        activeTabs.tab === "event log" ? formattedEndDate : formattedStartDate,
        formattedEndDate,
        activeTabs.tab === "event log" ? "day" : dateType
      )
    );
  };

  // Handle when the user changes the date to view.
  const onChangeDate = (change) => {
    // Create a modily function depending on the dateType currently selected
    const modify =
      change > 0
        ? { week: addDays, month: addMonths, day: addDays }[urlDateType]
        : { week: subDays, month: subMonths, day: subDays }[urlDateType];
    // For the week case we use the add/subDays function rather than the add/subWeek function as we want to always land on the same day of the week
    const modifiedDate =
      urlDateType === "week" ? modify(urlEndDate, 6) : modify(urlEndDate, 1);
    // Finally we check that the user isn't navigating beyond the bounds of the data
    const latestDate = endOfDay(new Date());
    const constrainedDate =
      modifiedDate > latestDate ? latestDate : modifiedDate;
    changePage(
      urlTab,
      constrainedDate,
      getFromDate(constrainedDate, urlDateType)
    );
  };

  // Invoke the custom hooks for managing reports and notes
  const { reportTypes, createReport } = useReportsHook(server, navigate);
  const { setRefreshHubNotes } = useHubDailyNotes(navigate, server, id);

  const reportTypesWithV1 = useMemo(
    () => [...reportTypes, { id: "general-report", title: "Behaviour Report" }],
    [reportTypes]
  );

  /* -- 
  The following custom hooks take care of behaviour data manipulation 
  Note that they depend on primative strings for dates and dateTypes so that their useEffect hooks only change on material date changes. 

  The following data are used in the charts. The 'usePrepare...' hooks only contain useEffects.
  Its responsibility is to return different data for the charts if any one of these variables change: serviceUserData, endDateString, dateType
  ---*/

  const { preparedMovementV3Data, prevPreparedMovementV3Data } =
    usePrepareMovementV3Data({
      movementV3Data: reduxMoveV3Data,
      startDateString: urlStartDateString,
      endDateString: urlEndDateString,
      prevStartDateString: prevStartDateString,
      prevEndDateString: prevEndDateString,
      dateType: urlDateType,
    });

  const todaysMovementV3Data = preparedMovementV3Data?.[0];
  const daytimeEventLog = todaysMovementV3Data?.day?.eventLog || {};
  const nighttimeEventLog = todaysMovementV3Data?.night?.eventLog || {};
  const todaysMovementV3eventLog = mergeMovementV3DayAndNightEventLog(
    daytimeEventLog,
    nighttimeEventLog
  );
  const { bathroomInference, previousBathroomInferenceData } =
    usePrepareBathroomData(
      reduxBathInferenceData,
      urlStartDateString,
      urlEndDateString,
      prevEndDateString,
      prevStartDateString,
      urlDateType
    );
  const { bedroomEvents } = usePrepareBedroomData(
    reduxBedroomData,
    urlStartDateString,
    urlEndDateString,
    urlDateType
  );
  const {
    temperatureRisk,
    temperatureData,
    oneDayOfTemperatureData,
    temperatureSentences,
  } = usePrepareTemperatureData(
    reduxTempDailyResultsData,
    urlStartDateString,
    urlEndDateString,
    urlDateType
  );
  const { sustenanceRisk, sustenanceData, sustenanceDataPrevious } =
    usePrepareSustenanceData(
      reduxSustenanceData,
      urlStartDateString,
      urlEndDateString,
      urlDateType
    );

  const { independenceData, independenceDataPrevious, frontDoorEvents } =
    usePrepareIndependenceData(
      reduxIndepData,
      reduxMainDoorData,
      urlStartDateString,
      urlEndDateString,
      urlDateType
    );

  const { timeOutsideData, timeOutsideDataPrevious } =
    usePrepareTimeOutsideData({
      reduxTimeOutsideData,
      urlStartDateString,
      urlEndDateString,
      urlDateType,
      prevEndDateString,
      prevStartDateString,
    });

  const { timeOutsideCombined, timeOutsideCombinedPrevious } =
    useCombineIndependenceAndTimeOutside({
      independenceData,
      independenceDataPrevious,
      timeOutsideData,
      timeOutsideDataPrevious,
    });

  /*-----End------*/

  /*
    Event log data
  */
  const movementEventLogEvents = {
    "hall.wall.motion": reduxEventLogData?.["hall.wall.motion"] || [],
  };
  const bathroomInferenceEventLogData =
    getBathroomEventLogDataFromBathroomInference(bathroomInference, urlEndDate);
  const { allMotionEvents } = getEventLogData(todaysMovementV3eventLog, true);
  const bathroomTimeStamps = getV1EventLogBathroomTimestamps({
    reduxMoveV3Data,
    urlEndDateString,
    prevEndDateString,
    urlEndDate,
  });

  // Assign booleans to whether we have behaviour data and an associated risk value
  const noMovementV3Data = !preparedMovementV3Data;
  const noTemperatureData =
    !temperatureRisk && temperatureRisk !== 0 && temperatureData?.length === 0;
  const noIndependenceData = timeOutsideCombined?.length === 0;
  const noSustenanceData = !sustenanceRisk && sustenanceRisk !== 0;
  const noBathroomActivityData =
    bathroomInference?.length === 0 && bathroomTimeStamps?.length === 0;

  const hasData =
    !noMovementV3Data ||
    !noTemperatureData ||
    !noIndependenceData ||
    !noSustenanceData ||
    !noBathroomActivityData;

  // ---- Get the hub notes when the service user id changes
  useEffect(() => {
    setRefreshHubNotes(true);
  }, [id, setRefreshHubNotes]);

  const sensorData = useGetHardwareStatus({
    serviceUserId: id,
    isInstalled: hasDevice,
    devicestring: serviceUser?.devicestring,
  });

  // Fade Modal
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  if (userIsJustAnInstaller) {
    return (
      <Page>
        <ServiceUserInfo
          serviceUser={serviceUser}
          isInstallerOnly={userIsJustAnInstaller}
          sensorData={sensorData}
          changePage={changePage}
          goToNext={goToNext}
          goToPrevious={goToPrevious}
          hubId={id}
        />
        {!hasDevice ? (
          <NoDataBox title={"This individual needs the Lilli kit installed"}>
            <PrimaryButton onClick={() => navigate(`/install/link/${id}`)}>
              Install now
            </PrimaryButton>
          </NoDataBox>
        ) : (
          <NavTabBody
            noData={!hasData}
            activeTab={{ tab: "devices" }}
            noDeviceString={!serviceUser?.devicestring}
            devices={
              <DevicesOverview
                serviceUser={serviceUser}
                sensorData={sensorData}
                indexOfSU={indexOfSU}
              />
            }
          />
        )}
      </Page>
    );
  } else {
    return (
      <Page>
        <ServiceUserInfo
          hubId={id}
          goToNext={goToNext}
          goToPrevious={goToPrevious}
          serviceUser={serviceUser}
          sensorData={sensorData}
          changePage={changePage}
          setEditProfile={() => {
            setShowAnimation(true);
            setModalSelected("EditProfile");
          }}
          setEditNotes={() => {
            setShowAnimation(true);
            setModalSelected("EditNotes");
          }}
          setNewReport={() => {
            setShowAnimation(true);
            setModalSelected("NewReport");
          }}
        />
        <NavigationTabs
          onChangeNavTab={changePage}
          activeTab={urlTab}
          navigationTabs={
            canSeeNotifications ? TABS_WITH_NOTIFICATIONS : NAVIGATION_TABS
          }
          organisationId={user?.organisationId}
        />
        {/* SCENARIO 1: If the SU does NOT have Lilli Hardware installed... */}
        {!hasDevice && (
          <NoDataBox title={noDeviceInstalledText}>
            {userHasInstallerRole && (
              <PrimaryButton onClick={() => navigate(`/install/link/${id}`)}>
                Install now
              </PrimaryButton>
            )}
          </NoDataBox>
        )}
        {/* DATE SELECTOR */}
        {/* SCENARIO 2A: If the SU has hardware installed and we're not on Devices Page */}
        {hasDevice &&
          urlTab.tab !== "devices" &&
          urlTab.tab !== "notifications" && (
            <DateSelector
              date={urlEndDate}
              from={urlStartDate}
              to={urlEndDate}
              earliestDate={earliestDate}
              onChange={onChangeDate}
              dateType={urlDateType}
              handleTodayButtonClick={handleTodayButtonClick}
              setDateType={(dateType) => {
                changePage(
                  urlTab,
                  urlEndDate,
                  getFromDate(urlEndDate, dateType),
                  dateType
                );
              }}
            />
          )}
        {/* NAVIGATION TABS */}
        {/* SCENARIO 2B: IF SU has Hardware & incoming data for at least 1 behaviour, then show all of the charts */}
        {urlTab.tab === "event log" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={urlDateType !== "day"}
            activeTab={urlTab}
            component={
              <EventLog
                frontDoorEvents={frontDoorEvents?.[0]}
                movementEvents={movementEventLogEvents}
                bathroomEvents={bathroomTimeStamps}
                bedroomEvents={bedroomEvents}
                fridgeEvents={
                  reduxEventLogData?.["kitchen.fridge.door"] || null
                }
              />
            }
          />
        )}
        {urlTab.tab === "overview" && hasDevice && (
          <NavTabBody
            noData={!hasData}
            activeTab={urlTab}
            dateType={urlDateType}
            component={
              <BehaviourOverview
                activeTab={urlTab}
                changePage={changePage}
                previousDataPeriod={prevPreparedMovementV3Data}
                movementV3Data={preparedMovementV3Data}
                sustenanceData={sustenanceData}
                sustenanceDataPrevious={sustenanceDataPrevious}
                independenceData={timeOutsideCombined}
                independenceDataPrevious={timeOutsideCombinedPrevious}
                frontDoorEvents={frontDoorEvents}
                temperatureData={temperatureData}
                oneDayOfTemperatureData={oneDayOfTemperatureData}
                // previously we included analysed bathroom inference data
                // however, to keep it consistent with daytime & nighttime
                // we are only using movementV3 data for the overview eventlog
                events={allMotionEvents}
                bathroomInference={bathroomInference}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                prevEndDate={prevEndDate}
                prevStartDate={prevStartDate}
                title={"Overview"}
                serviceUser={serviceUser}
              />
            }
          />
        )}
        {urlTab.tab === "day-time" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={noMovementV3Data}
            component={
              <BehaviourDayTime
                activeTab={urlTab}
                changePage={changePage}
                previousDataPeriod={prevPreparedMovementV3Data}
                movementV3Data={preparedMovementV3Data}
                daytimeMovementData={preparedMovementV3Data?.[0]?.day}
                independenceData={timeOutsideCombined}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                prevEndDate={prevEndDate}
                prevStartDate={prevStartDate}
                serviceUser={serviceUser}
              />
            }
          />
        )}
        {urlTab.tab === "night-time" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={noMovementV3Data}
            component={
              <BehaviourNightTime
                activeTab={urlTab}
                changePage={changePage}
                previousDataPeriod={prevPreparedMovementV3Data}
                movementV3Data={preparedMovementV3Data}
                nighttimeMovementData={preparedMovementV3Data?.[0]?.night}
                independenceData={timeOutsideCombined}
                independenceDataPrevious={timeOutsideCombinedPrevious}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                prevEndDate={prevEndDate}
                prevStartDate={prevStartDate}
                serviceUser={serviceUser}
              />
            }
          />
        )}
        {urlTab.tab === "sustenance" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={noSustenanceData}
            component={
              <BehaviourSustenance
                activeTab={urlTab}
                changePage={changePage}
                sustenanceRisk={sustenanceRisk}
                sustenanceData={sustenanceData}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                title={"Eating & Drinking"}
              />
            }
          />
        )}
        {urlTab.tab === "independence" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={noIndependenceData}
            component={
              <BehaviourIndependence
                activeTab={urlTab}
                changePage={changePage}
                independenceData={timeOutsideCombined}
                independenceDataPrevious={timeOutsideCombinedPrevious}
                dateType={urlDateType}
                title={"Time Outside"}
              />
            }
          />
        )}
        {urlTab.tab === "temperature" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={noTemperatureData}
            component={
              <BehaviourTemperature
                activeTab={urlTab}
                changePage={changePage}
                temperatureData={temperatureData}
                temperatureRisk={temperatureRisk}
                temperatureSentences={temperatureSentences}
                oneDayOfTemperatureData={oneDayOfTemperatureData}
                getRiskDates={getRiskDates}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                title={"Temperature"}
              />
            }
          />
        )}
        {urlTab.tab === "bathroom" && hasDevice && (
          <NavTabBody
            dateType={urlDateType}
            noData={false} // TO DO: noBathroomActivityData
            component={
              <BehaviourBathroomNew
                activeTab={urlTab}
                changePage={changePage}
                bathroomEventLogData={bathroomInferenceEventLogData}
                bathroomInference={bathroomInference}
                previousDataPeriod={previousBathroomInferenceData}
                bathroomRisk={0} // TO DO: we don't have bathroom risk
                getRiskDates={getRiskDates}
                dateType={urlDateType}
                from={urlStartDate}
                to={urlEndDate}
                prevEndDate={prevEndDate}
                prevStartDate={prevStartDate}
                title={"Bathroom"}
              />
            }
          />
        )}
        {urlTab.tab === "devices" && hasDevice && (
          <NavTabBody
            noData={!hasData}
            activeTab={urlTab}
            noDeviceString={!serviceUser?.devicestring}
            user={user}
            serviceUser={serviceUser}
            devices={
              <DevicesOverview
                serviceUser={serviceUser}
                indexOfSU={indexOfSU}
                sensorData={sensorData}
              />
            }
            noDeviceStringBox={
              <NoDataBox title={noDeviceInstalledText}>
                {userHasInstallerRole && (
                  <PrimaryButton
                    onClick={() => navigate(`/install/link/${id}`)}
                  >
                    Install now
                  </PrimaryButton>
                )}
              </NoDataBox>
            }
          />
        )}
        {urlTab.tab === "notifications" && hasDevice && canSeeNotifications && (
          <NavTabBody
            noData={false}
            activeTab={urlTab}
            user={user}
            serviceUser={serviceUser}
          >
            <ServiceUserNotificationSettings />
          </NavTabBody>
        )}
        {/* Modal: Edit profile */}
        {isVisible && modalSelected === "EditProfile" && (
          <NewStyleModal
            showCloseIcon={true}
            hide={() => {
              setShowAnimation(false);
            }}
            title="Edit profile"
            context="This user's details will be updated once you hit save"
            useFade={true}
            showAnimation={showAnimation}
          >
            <EditServiceUserProfile
              hubId={id}
              serviceUser={serviceUser}
              hide={() => {
                setShowAnimation(false);
              }}
              showRemoveUserModal={() => {
                setModalSelected("RemoveUser");
              }}
            />
          </NewStyleModal>
        )}
        {/* Modal: Remove SU modal */}
        {isVisible && modalSelected === "RemoveUser" && (
          <RemoveServiceUserModal
            hide={() => {
              setShowAnimation(false);
            }}
            serviceUser={serviceUser}
            hubId={id}
            showAnimation={showAnimation}
            setShowModal={setModalSelected}
            isManagerOrAdmin={isManagerOrAdmin}
          />
        )}
        {/* Modal: New Report */}
        {isVisible && modalSelected === "NewReport" && (
          <CreateNewReportModal
            skipSUSelection={true}
            serviceUsers={[serviceUser]}
            reportTypes={reportTypesWithV1}
            showAnimation={showAnimation}
            setShowAnimation={setShowAnimation}
            createReport={createReport}
          />
        )}
        {/* Modal: Edit notes */}
        {isVisible && modalSelected === "EditNotes" && (
          <NewStyleModal
            showCloseIcon={true}
            hide={() => {
              setShowAnimation(false);
            }}
            title={
              noteToDelete
                ? "Are you sure you want to delete?"
                : "Profile notes"
            }
            context={
              noteToDelete
                ? `You will be deleting the note created by ${noteToDelete.author} on ${noteToDelete.date}`
                : "This user's details will be updated once you hit save"
            }
            useFade={true}
            showAnimation={showAnimation}
          >
            <EditNotes
              hubId={id}
              setNoteToDelete={setNoteToDelete}
              noteToDelete={noteToDelete}
            />
          </NewStyleModal>
        )}
      </Page>
    );
  }
}

export default LilliUser;
