import {
  getReportStatusAndLocationV1,
  getReportStatusAndLocationV2,
  postPublishReportV1,
  postPublishReportV2,
} from "@intelligentlilli/api-layer";

interface DownloadReportProps {
  server: string;
  setDownloadingReport: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setDownloaded?: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  getReportStatusAPI: any;
  postPublishReportAPI: any;
  isMounted: boolean;
  origin?: string;
}

export const downloadReport = async ({
  server,
  setDownloadingReport,
  setDownloaded,
  id,
  setError,
  getReportStatusAPI,
  postPublishReportAPI,
  isMounted,
  origin,
}: DownloadReportProps): Promise<string | undefined> => {
  // Set the loading state from the component
  if (isMounted) {
    setDownloadingReport(true);
  }

  try {
    const res = await postPublishReportAPI(server, id, origin);
    if (res.ok) {
      let iterations = 15;
      return new Promise((resolve) => {
        const interval = window.setInterval(async () => {
          if (iterations > 0) {
            try {
              const statusRes = await getReportStatusAPI(
                server,
                id,
                res.body?.publicationId,
                origin
              );
              if (statusRes.body?.location && isMounted) {
                setDownloadingReport(false);
                window.clearInterval(interval);
                resolve(statusRes.body.location); // Return the report URL
                setDownloaded && setDownloaded(true);
              }
            } catch (error) {
              isMounted && setDownloadingReport(false);
              isMounted && setError(true);
            }
            iterations--;
          } else {
            window.clearInterval(interval);
            isMounted && setDownloadingReport(false);
            isMounted && setError(true);
          }
        }, 1500);
      });
    } else {
      isMounted && setDownloadingReport(false);
      isMounted && setError(true);
    }
  } catch (error) {
    if (isMounted) {
      setDownloadingReport(false);
      setError(true);
    }
  }
};

interface GenerateAndDownloadReportProps {
  report: any;
  server: string;
  downloadingReport?: boolean;
  setDownloadingReport: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setDownloaded: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  isMounted: React.MutableRefObject<boolean>;
}

export const generateAndDownloadReport = async ({
  report,
  server,
  downloadingReport,
  setDownloadingReport,
  setDownloaded,
  error,
  setError,
  isMounted,
}: GenerateAndDownloadReportProps) => {
  if (downloadingReport === true || error) {
    return;
  }

  setDownloadingReport(true);

  const reportId =
    report.report_type === "general-report" ? report.id : report.request_id;

  const getStatusAndLocation =
    report.report_type === "general-report"
      ? getReportStatusAndLocationV1
      : getReportStatusAndLocationV2;

  const publishReport =
    report.report_type === "general-report"
      ? postPublishReportV1
      : postPublishReportV2;

  const reportURL = await downloadReport({
    server,
    setDownloadingReport,
    id: reportId,
    setError,
    getReportStatusAPI: getStatusAndLocation,
    postPublishReportAPI: publishReport,
    setDownloaded,
    isMounted: isMounted.current,
  });

  if (reportURL) {
    // Automatically trigger the download
    const link = document.createElement("a");
    link.href = reportURL;
    link.setAttribute("download", ""); // Browser uses the filename from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
